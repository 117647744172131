.modal-btn-own {
  background-color: #245293;
  border: none;
  color: #fff;
  height: 48px;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  border-radius: 100px;
  cursor: pointer;
}
