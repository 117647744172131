.password-page-container {
  padding: 0.2rem 0;
}

.password-page-container h6 {
  color: #245293;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
}

.password-page-container .password-page-details {
  margin-top: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 7px;
  padding: 1.5rem 1.2rem;
}

.password-page-container .password-page-details .inner-contain {
  margin-top: 1.2rem;
}

.password-page-container .password-page-details p {
  color: #808080;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
}

.password-page-container .password-page-details .inner-contain h4 {
  color: #465174;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0.5rem;
}

.password-page-container .password-page-details input {
  margin-bottom: 1.5rem;
  padding: 0.8rem 0.8rem;
  width: 350px;
  border-radius: 20px;
  outline: none;
  border: 1px solid rgb(173, 173, 173);
}

.password-page-container .password-page-details .button-container {
  display: flex;
  gap: 15px;
  align-items: center;
}

.password-page-container .password-page-details .button-container .btn-save {
  background-color: #245293;
  color: #fff;
  padding: 12px 20px;
  border-radius: 24px;
  cursor: pointer;
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: 600;
}

.password-page-container .password-page-details .button-container .btn-forgot {
  background-color: #fff;
  color: #245293;
  cursor: pointer;
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: 600;
}

@media only screen and (max-width: 750px) {
  .password-page-container .password-page-details {
    height: 550px;
    padding: 0.7rem;
  }
}

@media only screen and (max-width: 500px) {
  .password-page-container .password-page-details input {
    margin-bottom: 1.5rem;
    padding: 0.8rem 0.8rem;
    width: 100%;
    border-radius: 20px;
    outline: none;
    border: 1px solid rgb(173, 173, 173);
  }
}
