.candidates-container .candidates-right {
  padding: 1.5rem 0.9rem;
  width: 100%;
}

.candidates-container .candidates-right .heading-right-top {
  color: #465174;
  font-size: 30px;
  font-weight: 700;
  line-height: 44px;
}

.candidates-container .candidates-right .candidates-content-box {
  margin-top: 1rem;
  padding: 1.5rem;
  background-color: #fff;
  border-radius: 24px;
}

.candidates-container .candidates-right .candidates-content-box .search-bar {
  border: 1px solid rgb(216, 216, 216);
  width: 300px;
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 0.6rem 0 0.6rem 0.5rem;
  border-radius: 6px;
  font-size: 16px;
}

.candidates-container
  .candidates-right
  .candidates-content-box
  .search-bar
  input {
  border: none;
  width: 100%;
  outline: none;
  color: #9ea8b8;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.candidates-container .candidates-right .candidates-content-box .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.candidates-container
  .candidates-right
  .candidates-content-box
  .header
  .header-box-select {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.candidates-container
  .candidates-right
  .candidates-content-box
  .header
  .header-box-select
  .box-select {
  border-radius: 4px;
  border: 1px solid #d8dde5;
  background: #fff;
  padding: 8px 12px;
}

.candidates-container
  .candidates-right
  .candidates-content-box
  .header
  .header-box-select
  .box-select
  label {
  color: #757f8f;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.candidates-container
  .candidates-right
  .candidates-content-box
  .header
  .header-box-select
  .flex {
  display: flex;
  align-items: center;
  gap: 5px;
}

.candidates-container .candidates-right .candidates-content-box .header select {
  color: #1c2533;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  border: none;
  background: url(../../../assets/arrow-down.svg) no-repeat right transparent;
  outline: none;
}

.candidates-container
  .candidates-right
  .candidates-content-box
  .candidates-options-box {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
}

.candidates-container
  .candidates-right
  .candidates-content-box
  .candidates-options-box
  .options-left {
  display: flex;
  gap: 30px;
  align-items: center;
}

.candidates-container
  .candidates-right
  .candidates-content-box
  .candidates-options-box
  .non-active {
  color: #757f8f;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  cursor: pointer;
  text-transform: uppercase;
}

.candidates-container
  .candidates-right
  .candidates-content-box
  .candidates-options-box
  .active {
  color: #19a662;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  cursor: pointer;
  padding-bottom: 2px;
  border-bottom: 2px solid #19a662;
  text-transform: uppercase;
}

.candidates-container
  .candidates-right
  .candidates-content-box
  .candidates-options-box
  .options-right
  p {
  color: #757f8f;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.candidates-right .candidates-result {
  border: 1px solid rgb(223, 223, 223);
  border-radius: 10px;
}

.candidates-right .candidates-result .candidates-result-headings {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f7fa;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 1rem 0 1rem 1.5rem;
}

.candidates-right .candidates-result .candidates-result-headings span {
  width: 15%;
  color: #1c2533;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}

.candidates-right .candidates-result .candidates-result-headings .name {
  width: 25%;
}

.candidates-right
  .candidates-result
  .candidates-result-box
  .single-candidate
  .single-candidate-left {
  display: none;
}

.candidates-right
  .candidates-result
  .candidates-result-box
  .single-candidate
  .single-candidate-right {
  display: flex;
  width: 100%;
  padding: 0.5rem 0 0.5rem 1.5rem;
  border-bottom: 1px solid #d8dde5;
}

.candidates-right
  .candidates-result
  .candidates-result-box
  .single-candidate
  .single-candidate-right
  .single {
  width: 15%;
}

.candidates-right
  .candidates-result
  .candidates-result-box
  .single-candidate
  .single-candidate-right
  .mobile-options {
  display: none;
}

.candidates-right
  .candidates-result
  .candidates-result-box
  .single-candidate
  .single-candidate-right
  .dot-bg {
  width: 15%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* background-color: red; */
}

.candidates-right
  .candidates-result
  .candidates-result-box
  .single-candidate
  .single-candidate-right
  .dot-bg
  .dot {
  cursor: pointer;
}

.candidates-right
  .candidates-result
  .candidates-result-box
  .single-candidate
  .single-candidate-right
  .name {
  width: 25%;
  color: #1c2533;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  align-items: center;
}

.candidates-right
  .candidates-result
  .candidates-result-box
  .single-candidate
  .single-candidate-right
  .charAt {
  background-color: #f5f7fa;
  margin-right: 8px;
  color: rgb(3, 42, 151);
  padding: 0.2rem 0.5rem;
  border-radius: 50%;
  color: #2d54e0;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}

.candidates-right
  .candidates-result
  .candidates-result-box
  .single-candidate
  .single-candidate-right
  .review {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #1c2533;
  font-size: 14px;
}

.candidates-right
  .candidates-result
  .candidates-result-box
  .single-candidate
  .single-candidate-right
  .status {
  width: 15%;
  display: flex;
  align-items: center;
}

.candidates-right
  .candidates-result
  .candidates-result-box
  .single-candidate
  .single-candidate-right
  .pend {
  color: #f5a656;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  border-radius: 24px;
  background: rgba(245, 166, 86, 0.15);
  padding: 5px 9px;
  width: fit-content;
}

.candidates-right
  .candidates-result
  .candidates-result-box
  .single-candidate
  .single-candidate-right
  .acc {
  color: green;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  border-radius: 24px;
  background: rgba(19, 220, 124, 0.15);
  padding: 5px 9px;
  width: fit-content;
}

.candidates-right
  .candidates-result
  .candidates-result-box
  .single-candidate
  .single-candidate-right
  .rej {
  color: red;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  border-radius: 24px;
  background: rgba(219, 62, 77, 0.15);
  padding: 5px 9px;
  width: fit-content;
}

.modal-completed {
  padding: 30px 10px 20px;
}

.modal-completed p {
  font-size: 18px;
  font-weight: 500;
  color: black;
  text-align: center;
}

.modal-completed .btn-group-modal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 20px;
}

@media only screen and (max-width: 1000px) {
  .candidates-container .candidates-right {
    padding-top: 5rem;
  }

  .candidates-container
    .candidates-right
    .candidates-content-box
    .candidates-options-box {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 1.3rem;
    margin: 1rem 0;
  }
}

@media only screen and (max-width: 800px) {
  .candidates-container .candidates-right .candidates-content-box .header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
  }
}

@media only screen and (max-width: 750px) {
  .candidates-right .candidates-result {
    border: 1px solid rgb(223, 223, 223);
    border-radius: 10px;
  }
}

@media only screen and (max-width: 680px) {
  .candidates-container .candidates-right .candidates-content-box {
    margin-top: 1rem;
    padding: 1rem 0.5rem;
    background-color: #fff;
    border-radius: 15px;
  }

  .candidates-container
    .candidates-right
    .candidates-content-box
    .candidates-result
    .candidates-result-headings {
    display: none;
  }

  .candidates-right .candidates-result {
    border: none;
  }

  .candidates-right
    .candidates-result
    .candidates-result-box
    .single-candidate
    .single-candidate-right
    .dot-bg {
    display: none;
  }

  .candidates-right
    .candidates-result
    .candidates-result-box
    .single-candidate
    .single-candidate-right
    .mobile-options {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .candidates-right
    .candidates-result
    .candidates-result-box
    .single-candidate
    .single-candidate-right
    .mobile-options
    button {
    font-size: 14px;
    font-weight: 800;
    padding: 8px 0;
    width: 100%;
    cursor: pointer;
    border-radius: 20px;
    outline: none;
    border: none;
  }

  .candidates-right
    .candidates-result
    .candidates-result-box
    .single-candidate
    .single-candidate-right
    .mobile-options
    .message {
    background-color: transparent;
    color: #245293;
    border: 1px solid #245293;
  }

  .candidates-right
    .candidates-result
    .candidates-result-box
    .single-candidate
    .single-candidate-right
    .mobile-options
    .delete {
    background-color: #245293;
    border: 1px solid #245293;
    color: #fff;
  }

  .candidates-right
    .candidates-result
    .candidates-result-box
    .single-candidate
    .single-candidate-right
    .name {
    width: 100%;
  }

  .candidates-right
    .candidates-result
    .candidates-result-box
    .single-candidate
    .single-candidate-right
    .single {
    width: 100%;
  }

  .candidates-right
    .candidates-result
    .candidates-result-box
    .single-candidate
    .single-candidate-right
    .download {
    width: 100%;
  }

  .candidates-right
    .candidates-result
    .candidates-result-box
    .single-candidate
    .single-candidate-right
    .status {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .candidates-right
    .candidates-result
    .candidates-result-box
    .single-candidate
    .single-candidate-left {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .candidates-right
    .candidates-result
    .candidates-result-box
    .single-candidate
    .single-candidate-left
    h5 {
    color: #1c2533;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
  }

  .candidates-right
    .candidates-result
    .candidates-result-box
    .single-candidate
    .single-candidate-right {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    flex-basis: 50%;
    padding: 0;
    border: none;
  }

  .candidates-right
    .candidates-result
    .candidates-result-box
    .single-candidate {
    display: flex;
    flex-basis: 50%;
    border-top: 1px solid rgb(192, 192, 192);
    border-bottom: 1px solid rgb(192, 192, 192);
    gap: 3rem;
    padding: 1rem 0;
  }

  .candidates-right
    .candidates-result
    .candidates-result-box
    .single-candidate
    .single-candidate-right
    .single.download {
    justify-content: left;
    gap: 5px;
    padding: 0.5rem 0;
  }

  .candidates-container .candidates-right .candidates-content-box .search-bar {
    border: 1px solid rgb(216, 216, 216);
    width: 90%;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0.6rem 0 0.6rem 0.5rem;
    border-radius: 6px;
  }

  .candidates-container
    .candidates-right
    .candidates-content-box
    .candidates-options-box
    .options-left {
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 550px) {
  .candidates-container .candidates-right .candidates-content-box .search-bar {
    width: 100%;
  }

  .candidates-container
    .candidates-right
    .candidates-content-box
    .candidates-options-box
    .options-left {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    column-gap: 15px;
    row-gap: 15px;
    margin-top: 0.5rem;
  }

  .candidates-container
    .candidates-right
    .candidates-content-box
    .header
    .header-box-select {
    display: flex;
    align-items: center;
    gap: 0rem;
    justify-content: space-between;
    width: 100%;
  }

  .candidates-container
    .candidates-right
    .candidates-content-box
    .header
    .header-box-select
    .box-select {
    border-radius: 2px;
    border: 1px solid #d8dde5;
    background: #fff;
    padding: 3px 7px;
  }
}

@media only screen and (max-width: 400px) {
  .candidates-container
    .candidates-right
    .candidates-content-box
    .header
    .header-box-select
    .box-select {
    border-radius: 2px;
    border: 1px solid #d8dde5;
    background: #fff;
    padding: 2px 3px;
  }

  .candidates-container
    .candidates-right
    .candidates-content-box
    .header
    select {
    color: #1c2533;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    border: none;
    background: url(../../../assets/arrow-down.svg) no-repeat right transparent;
    outline: none;
  }
}
