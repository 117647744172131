.terms-body {
  padding-bottom: 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-top: 8rem;
}

.terms-body .terms-inner {
  width: 85%;
  margin: 0 auto;
  padding: 2rem;
  background-color: #fff;
  margin-bottom: 35px;
  border-radius: 10px;
}

.terms-body .terms-inner h4 {
  text-align: center;
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 19px;
  color: #245293;
}

.terms-body .terms-inner .head {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 19px;
  margin-top: 1rem;
  text-transform: uppercase;
}

@media only screen and (max-width: 1250px) {
  .terms-body .terms-inner {
    width: 90%;
  }

  .terms-body .terms-inner h4 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 2rem;
  }

  .terms-body .terms-inner .head {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: 1rem;
  }
}

@media only screen and (max-width: 900px) {
  .terms-body .terms-inner {
    width: 95%;
    padding: 2rem 1rem;
  }
}
