.aboutwhy-body {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%
}

.aboutwhy-body .aboutwhy-container {
    width: 75%;
    display: flex;
    justify-content: space-between;
    margin-top: 2.5rem;
    margin-bottom: 6rem;
    gap: 40px
}

.aboutwhy-body .aboutwhy-container .first {
    width: 50%;
    padding-top: 50px;
}

.aboutwhy-body .aboutwhy-container .header {
    position: relative;
    z-index: 8;
}

.aboutwhy-body .aboutwhy-container .header h2 {
    color: #245293;
    font-size: 40px;
    font-weight: 800;
    line-height: 61px;
}

.aboutwhy-body .aboutwhy-container .header .first-para {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.aboutwhy-body .aboutwhy-container .header .first-para p {
    color: #245293;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
}

.aboutwhy-body .aboutwhy-container .header .yellowone {
    position: absolute;
    top: 6px;
    left: 23px;
    z-index: -1;
}

.aboutwhy-body .aboutwhy-container .header .yellowtwo {
    position: absolute;
    top: 15px;
    left: 30px;
    z-index: -3;
}

.aboutwhy-body .aboutwhy-container .second {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 3;
}

.aboutwhy-body .aboutwhy-container .second img {
    width: 100%
}

.aboutwhy-body .aboutwhy-container .second .bgpeoplehero {
    position: absolute;
    top: -30px;
    right: -10px;
    z-index: -1;
}

@media only screen and (max-width: 1350px) {
    .aboutwhy-body .aboutwhy-container {
        width: 85%;
        gap: 20px
    }

    .aboutwhy-body .aboutwhy-container .header h2 {
        font-size: 32px;
        line-height: 51px;
    }

    .aboutwhy-body .aboutwhy-container .header .yellowone {
        width: 320px;
        height: 40px;
    }
    
    .aboutwhy-body .aboutwhy-container .header .yellowtwo {
        width: 320px;
        height: 40px;
    }

    .aboutwhy-body .aboutwhy-container .header .first-para {
        gap: 10px;
    }

    .aboutwhy-body .aboutwhy-container .header .first-para p {
        font-size: 16px;
    }
}

@media only screen and (max-width: 900px) {
    .aboutwhy-body .aboutwhy-container {
        width: 95%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1rem;
        margin-bottom: 3rem;
        gap: 60px
    }

    .aboutwhy-body .aboutwhy-container .first {
        width: 100%;
        padding-top: 10px;
    }

    .aboutwhy-body .aboutwhy-container .second {
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 3;
    }
}
