.contact-main {
    width: 100%;
    border-radius: 19px;
    padding: 3rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.contact-main .contact-main-content {
    width: 75%;
    margin: 120px auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contact-main .contact-main-content h1 {
    font-size: 48px;
    font-weight: 800;
    line-height: 58px;
    color:#245293;
}

.contact-main .contact-main-content p {
    padding-top: 10px;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    color: #808080;
}


.flex-contact-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.flex-contact-container .flex-contact {
    display: flex;
    align-items: center;
    gap: 10px
}

.flex-contact-container .flex-contact p {
    font-size: 15px;
    color: #808080;
    font-weight: 500;
}

.flex-contact-container .flex-contact .underline {
    text-decoration: underline;
}

@media only screen and (max-width: 1250px) {
    .contact-main .contact-main-content {
        width: 80%;
    }
}

@media only screen and (max-width: 900px) {
    .contact-main .contact-main-content {
        width: 95%;
    }

    .contact-main .contact-main-content h1 {
        font-size: 40px;
        font-weight: 800;
        line-height: 50px;
        color:#245293;
    }
}