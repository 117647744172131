.explore-container {
    width: 85%;
    margin: 0 auto;
    padding: 2rem;
    background-color: #fff;
    margin-bottom: 35px;
    border-radius: 10px;
}

.explore-container h3 {
    text-align: center;
    font-size: 32px;
    font-weight: 800;
    margin-bottom: 19px;
    color:#245293;
}

.explore-container .explore-tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.6rem;
    /* margin-bottom: 19px; */
}

.explore-container .explore-tabs .explore-tabs {
    border: 1px solid #94D3EA;
    background: #fff;
    border-radius: 20px;
    cursor: pointer;
    font-size: 18px;
    color: #245293;
    padding: 0.3rem 1rem;
}

.explore-container .explore-tabs .explore-tabs-active{
    background: #A9DBEE4D;
    border: 1px solid #36ADD7;
    border-radius: 20px;
    cursor: pointer;
    font-size: 18px;
    color: #245293;
    padding: 0.3rem 1rem;
}

.explore-container .explore-text {
    /* width: 67%;
    margin: 1rem auto;
    text-align: center;
    color: #245293;
    line-height: 1.6; */
    display: none;
}

.explore-container .explore-text-show {
    width: 70%;
    margin: 1rem auto;
    text-align: center;
    color: #245293;
    line-height: 1.6;
}

.explore-container .explore-boxes-container {
    margin-top: 20px;
    padding: 3rem 1rem;
    display: flex;
    justify-content: center;
    width: 90%;
    margin: 0 auto;
}

.explore-container .explore-boxes-container .explore-box {
    display: block;
    padding: 30px;
}

.explore-container .explore-boxes-container h5 {
    margin: 10px 0;
    font-size: 20px;
}

.explore-container .explore-boxes-container .square-green{
    color: #36A695;
    font-weight: 700;
}

.explore-container .explore-boxes-container .square-orange{
    color: #F5A656;
    font-weight: 700;

}

.explore-container .explore-boxes-container .square-red {
    color: #DB3E4D;
    font-weight: 700;

}

.explore-container .explore-boxes-container {
    color: #808080;
    font-size: 17px;
}

@media only screen and (max-width: 1250px) {
    .explore-container {
        width: 90%;
    }
}

/* @media only screen and (max-width: 800px) {
    .explore-container .explore-boxes-container {
        flex-direction: column;
    }

    .explore-container .explore-boxes-container .explore-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .explore-container .explore-boxes-container p {
        text-align: center;
    }
} */

@media only screen and (max-width: 900px) {
    .explore-container {
        width: 95%;
        padding: 2rem 0.4rem;
    }    

    .explore-container .explore-tabs {
        flex-direction: column;
        gap: 1rem;
        /* margin-bottom: 25px; */
    }

    .explore-container .explore-text-show {
        width: 90%;
    }
    
    .explore-container .explore-tabs .explore-tabs-1 {
        padding: 0.3rem 0.6rem;
        font-size: 14px;
        text-align: center;
    }
    
    .explore-container .explore-tabs .explore-tabs-2 {
        padding: 0.3rem 0.6rem;
        font-size: 14px;
        text-align: center;
    }

    .explore-container .explore-text {
        width: 90%;
        text-align: center;
        margin-bottom: 25px;
    }

    .explore-container .explore-boxes-container {
        padding: 1rem 1rem;
        flex-wrap: wrap;
        width: 95%;
    }

    .explore-container .explore-boxes-container .explore-box {
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 330px;
    }

    .explore-container .explore-boxes-container p {
        text-align: center;
    }
}