.notification-page-container h6 {
  margin-bottom: 1rem;
  font-size: 22px;
  font-weight: 600;
  color: #245293;
}

.notification-page-container .notification-page-content {
  border-radius: 7px;
  padding: 0.2rem 0;
}

.notification-page-container .notification-page-content .notification-flex-box {
  display: flex;
  gap: 3rem;
  border-bottom: 1px solid #ededed;
  padding: 1.1rem 0.5rem;
  border-radius: 5px;
}

.notification-page-container
  .notification-page-content
  .notification-flex-box
  .left-notification {
  width: 45%;
}

.notification-page-container
  .notification-page-content
  .notification-flex-box
  .left
  h4 {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.notification-page-container
  .notification-page-content
  .notification-flex-box
  .left
  p {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
}

.notification-page-container
  .notification-page-content
  .notification-flex-box
  .toggle-box {
  display: flex;
  gap: 5px;
}

.notification-page-container
  .notification-page-content
  .notification-flex-box
  .right-notification {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.notification-page-container
  .notification-page-content
  .notification-flex-box
  .right
  .toggle-boxspan {
  color: #353535;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
  cursor: pointer;
}

.toggle-switch input[type="checkbox"] {
  display: none;
}

.toggle-switch-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ddd;
  border-radius: 20px;
  box-shadow: inset 0 0 0 2px #ccc;
  transition: background-color 0.3s ease-in-out;
}

.toggle-switch-handle {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}

.toggle-switch::before {
  content: "";
  position: absolute;
  top: -25px;
  right: -35px;
  font-size: 12px;
  font-weight: bold;
  color: #aaa;
  text-shadow: 1px 1px #fff;
  transition: color 0.3s ease-in-out;
}

.toggle-switch input[type="checkbox"]:checked + .toggle-switch-handle {
  transform: translateX(45px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 3px #05c46b;
}

.toggle-switch input[type="checkbox"]:checked + .toggle-switch-background {
  background-color: #05c46b;
  box-shadow: inset 0 0 0 2px #04b360;
}

.toggle-switch input[type="checkbox"]:checked + .toggle-switch:before {
  content: "On";
  color: #05c46b;
  right: -15px;
}

.toggle-switch
  input[type="checkbox"]:checked
  + .toggle-switch-background
  .toggle-switch-handle {
  transform: translateX(18px);
}

@media only screen and (max-width: 750px) {
  .notification-page-container
    .notification-page-content
    .notification-flex-box {
    display: flex;
    justify-content: space-between;
    padding: 1.1rem 0.5rem;
    border-radius: 5px;
  }
}
