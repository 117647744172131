.dashboard-modal h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #245293;
  text-transform: uppercase;
}

.dashboard-modal .content {
  margin: 1rem 0;
}

.dashboard-modal .content .input {
  display: flex;
  flex-direction: column;
}

.dashboard-modal .content .input textarea {
  font-size: 30px;
}

.dashboard-modal .content .min-box {
  min-height: 150px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 1rem 0;
  margin-top: 1rem 0;
  height: 400px;
  overflow-y: scroll;
}

.dashboard-modal .content .min-box .loading-duties {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.dashboard-modal .content .min-box .no-res {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-modal .content .min-box .res-item {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.dashboard-modal .content .min-box .res-item .res-item-content {
  display: flex;
  /* align-items: center; */
  gap: 10px;
  width: 85%;
}

.dashboard-modal .content .min-box .res-item .bg-dot {
  width: 6%;
  padding-top: 7px;
}

.dashboard-modal .content .min-box .res-item .res-item-words {
  width: 94%;
}

.dashboard-modal .content .min-box .res-item .res-item-words p {
  font-size: 20px;
}

.dashboard-modal .content .min-box .res-item .res-bg-icon {
  width: 10%;
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: center;
}

.dashboard-modal .content .min-box .res-item .res-bg-icon .icon-btn {
  cursor: pointer;

  font-size: 20px;
}

.dashboard-modal .content .min-box .res-item p {
  font-size: 14px;
  font-weight: 600;
}

.dashboard-modal .content .min-box .res-item p::first-letter {
  text-transform: capitalize;
}

.dashboard-modal .content .min-box .res-item .dot-num {
  background-color: #000;
  height: 8px;
  width: 8px;
  border-radius: 50%;
}

.dashboard-modal .content .input textarea {
  border-radius: 10px;
  background: #fff;
  height: 70px;
  padding: 1rem;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

.dashboard-modal .content .btn {
  width: 100px;
}

.dashboard-modal .btn-bg {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
