.goals-bg {
    display: flex;
    justify-content: center;
}

.goals-bg .goals-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 85%;
}

.goals-bg .goals-content h2 {
    font-size: 40px;
    line-height: 61px;
    font-weight: 800;
    color: #245293;
}

.goals-bg .goals-content .img-cover {
    margin: 2rem auto;
    width: 100%;
}

.goals-bg .goals-content .img-cover img {
    width: 100%;
    height: fit-content;
    object-fit: contain;
}

.goals-bg .goals-content .desc {
    width: 80%;
}

.goals-bg .goals-content .desc p {
    text-align: center;
    font-size: 18px;
    line-height: 27px;
    font-weight: 400;
    color: #245293;
}

.goals-bg .goals-content .grid-flex {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    width: 100%;
    margin: 50px 0;

}

@media only screen and (max-width: 1350px) {
    .goals-bg .goals-content {
        width: 90%;
    }

    .goals-bg .goals-content .desc p {
        font-size: 15px;
    }
}

@media only screen and (max-width: 900px) {
    .goals-bg .goals-content {
        width: 95%;
    }

    .goals-bg .goals-content h2 {
        font-size: 30px;
        line-height: 40px;
        font-weight: 700;
    }
} 

@media only screen and (max-width: 750px) {
    .goals-bg .goals-content .grid-flex {
        display: flex;
        flex-wrap: wrap;
    }

    .goals-bg .goals-content .desc {
        width: 90%;
    }
}