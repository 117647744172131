.join-body {
    display: flex;
    justify-content: center;
}

.join-body .join-container {
    background: url(../../assets/bboo.svg);
    background-color: #fff;
    border-radius: 15px;
    width: 85%;
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 15px 0 60px;
}

.join-body .join-container .box {
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.join-body .join-container .box h2 {
    font-size: 44px;
    font-weight: 800;
    line-height: 61px;
    color: #245293;
    text-align: center;
}

.join-body .join-container .box p {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    color: #245293;
    text-align: center;
}

.join-body .join-container .box .join-input-email {
    margin: 25px 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    border: 1px solid #808080;
    background-color: #fff;
    border-radius: 30px;
}

.join-body .join-container .box .join-input-email input {
    border: none;
    outline: none;
    color:#808080;
    padding-left: 10px;
}

.join-body .join-container .box .join-input-email button {
    display: flex;
    align-items: center;
    text-align: center;
    border: none;
    width: 30%;
    justify-content: center;
    height: 40px;
    background-color: #245293;
    border-radius: 20px;
    color: #fff;
}

.join-body .join-container .box .people {
    display: flex;
    align-items: center;
    gap: 5px;
}

.join-body .join-container .box .people span {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #245293
}

@media only screen and (max-width: 1350px) {
    .join-body .join-container {
        width: 90%;
    }
}

@media only screen and (max-width: 900px) {
    .join-body .join-container {
        width: 95%;
    }

    .join-body .join-container .box {
        width: 90%;
    }

    .join-body .join-container .box h2 {
        font-size: 30px;
        font-weight: 700;
        line-height: 40px;
    }

    .join-body .join-container .box p {
        font-size: 15px;
        line-height: 24px;
    }

    .join-body .join-container .box .join-input-email {
        margin: 25px 0;
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        align-items: center;
        padding: 0;
        border: none;
        background-color: #fff;
        border-radius: 0;
    }
    
    .join-body .join-container .box .join-input-email input {
        border: 1px solid #808080;
        padding-left: 5px;
        border-radius: 20px;
        padding: 1rem;
        width: 100%;
    }
    
    .join-body .join-container .box .join-input-email button {
        display: flex;
        align-items: center;
        text-align: center;
        gap: 10px;
        border: none;
        width: 100%;
        justify-content: center;
        height: 50px;
        background-color: #245293;
        border-radius: 20px;
        color: #fff;
        font-size: 16px;
    }
    

    .join-body .join-container .box .people {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .join-body .join-container .box .people span {
        font-size: 12px;
        line-height: 18px
    }
}