.dashboard-modal-box {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-modal-box .dashboard-modal-content {
  background-color: #fff;
  padding: 1rem 1.5rem;
  border-radius: 15px;
  z-index: 5;
}

.spacing-top {
  margin-top: 2rem;
}

/* review modal */
.dashboard-modal-box .dashboard-modal {
  /* border: 1px solid red; */
}

.dashboard-modal-box .dashboard-modal hr {
  border-top: 1px solid #a9dbee;
  margin: 1rem 0 0 0.4rem;
}

.dashboard-modal-box .dashboard-modal .review-logo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dashboard-modal-box .dashboard-modal .review-logo .review-logo-img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid grey;
  padding: 5px;
  margin: 0.8rem 0;
}

.dashboard-modal-box .dashboard-modal .review-logo .review-logo-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dashboard-modal-box .dashboard-modal .review-logo h4 {
  font-size: 15px;
  color: #245293;
}

.dashboard-modal-box .dashboard-modal .review-logo .title-bg .rate {
  margin-top: 3px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.dashboard-modal-box .dashboard-modal .review-logo .title-bg .rate span {
  color: #aabcd6;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
}

.dashboard-modal-box .dashboard-modal .experience-review-box {
  margin-top: 20px;
  width: 100%;
}

.dashboard-modal-box .dashboard-modal .experience-review-box p {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.dashboard-modal-box .dashboard-modal .experience-review-box textarea {
  margin: 0.3rem 0 1rem;
  width: 100%;
  height: 96px;
  padding: 1rem;
  border-radius: 100px;
  border: 1px solid #efefef;
  background: #fff;
}
