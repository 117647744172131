.search-modal-container {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.search-modal-container .home-box-one-search-box {
    border-radius: 6px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0.6rem;
    gap: 15px;
    width: 90%;
    margin: 0 auto;
    position: relative;
    margin-top: 5rem;
}

.search-modal-container .close-container {
    position: relative;
    height: 50px;
    background-color: #fff;
    margin-top: 1rem;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-modal-container .close-container .cancel-close-searchbox {
    font-size: 3rem;
}

.search-modal-container .home-box-one-search-box .input-1 {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 15px;
    width: 100%;
    border-bottom: 1px solid #c9c8c8;
}

.search-modal-container .home-box-one-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.search-modal-container .home-box-one-button button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    background-color: #245293;
    color: #fff;
    font-size: 16px;
    padding: 8px 15px;
    cursor: pointer;
    border-radius: 20px;
    width: 100%;
}

.search-modal-container .home-box-one-search-box input {
    padding: 2px 5px;
    border: none;
    outline: none;
    width: 150px;
}

.search-modal-container .filter {
    padding: 10px;
}
