.Posted-jobs-container {
  display: flex;
  gap: 1rem;
}

.Posted-jobs-container h2 {
  font-size: 29px;
  color: #465174;
  margin: 0.5rem 0;
  margin-top: 1.2rem;
}

.Posted-jobs-container .posted-jobs-right {
  width: 100%;
  padding: 0rem 1rem;
}

.Posted-jobs-container .posted-jobs-right .posted-jobs-content {
  background-color: #fff;
  border-radius: 7px;
  margin-top: 2rem;
  padding: 2rem 0;
}

.Posted-jobs-container .posted-jobs-right .posted-jobs-content .inner-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.Posted-jobs-container
  .posted-jobs-right
  .posted-jobs-content
  .postJobs-jobItem {
  width: 30%;
  cursor: pointer;
}

@media only screen and (max-width: 1200px) {
  .Posted-jobs-container
    .posted-jobs-right
    .posted-jobs-content
    .postJobs-jobItem {
    width: 45%;
  }
}

@media only screen and (max-width: 1000px) {
  .Posted-jobs-container .posted-jobs-right {
    margin-top: 5rem;
  }
}

@media only screen and (max-width: 750px) {
  .Posted-jobs-container
    .posted-jobs-right
    .posted-jobs-content
    .postJobs-jobItem {
    width: 95%;
  }

  .Posted-jobs-container .posted-jobs-right {
    padding: 0rem 0.5rem;
  }
}
