.header-payment-modal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.header-payment-modal h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -1px;
  text-align: center;
  color: #021424;
}
