.client-slider-container {
  width: 85%;
  margin: 0 auto;
  background: url(../../assets/bg-client.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2rem 3rem;
  border-radius: 15px;
  margin-bottom: 35px;
}

.client-slider-container .client-speak {
  font-size: 35px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 0.8rem;
}

.client-slider-container .customer-item {
  width: 300px;
  padding: 0.8rem 0.2rem;
}

.client-slider-container .customer-item .customer-content-top {
  background-color: #fff;
  height: 120px;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  margin: 0px 1rem 1rem;
}

.client-slider-container .customer-item .customer-content-top h4 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
}

.client-slider-container .customer-item .customer-content-top p {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #18191f;
}

.client-slider-container .customer-item .customer-content-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.7rem;
}

.client-slider-container .customer-item .customer-content-bottom img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.client-slider-container
  .customer-item
  .customer-content-bottom
  .customer-extra
  .customer-name {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.client-slider-container
  .customer-item
  .customer-content-bottom
  .customer-extra
  .customer-title {
  font-size: 12px;
  font-weight: 400;
  color: #fff;
}

@media only screen and (max-width: 1450px) {
  .client-slider-container .customer-item .customer-content-top p {
    font-size: 11px;
    font-weight: 400;
    line-height: 1.5;
    color: #18191f;
  }
}

@media only screen and (max-width: 1250px) {
  .client-slider-container {
    width: 90%;
  }
}

@media only screen and (max-width: 1050px) {
  .client-slider-container .customer-item .customer-content-top p {
    font-size: 10px;
    font-weight: 400;
    line-height: 1.5;
    color: #18191f;
  }
}

@media only screen and (max-width: 900px) {
  .client-slider-container {
    width: 95%;
    background-repeat: repeat;
    padding: 2rem 1rem;
  }

  .client-slider-container .client-speak {
    font-size: 30px;
  }

  .client-slider-container .customer-box-container {
    flex-wrap: wrap;
  }

  .client-slider-container .customer-item {
    
    width: 100%;
    padding: 0.8rem 0.2rem;
  }
  .client-slider-container .customer-item .customer-content-top {
    height: auto;
  }
}
