.settings-container .settings-right {
  padding: 1.2rem 1.2rem 0;
}

.settings-container .settings-right h3 {
  color: #465174;
  font-size: 24px;
  font-weight: 700;
  line-height: 56px;
}

.settings-container .settings-right .settings-right-bottom {
  background-color: #fff;
  border-radius: 15px;
  display: flex;
  gap: 25px;
  margin: 1rem 0 1rem;
  padding: 1rem;
}

.settings-container .settings-right .settings-right-bottom .settings-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 1rem;
}

.settings-container
  .settings-right
  .settings-right-bottom
  .settings-options
  span {
  padding: 6px 8px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  line-height: 22px;
  font-size: 16px;
  display: block;
  width: fit-content;
}

.settings-container
  .settings-right
  .settings-right-bottom
  .settings-options
  .settings-active {
  background: #e0edff;
  color: #245293;
}

.settings-container
  .settings-right
  .settings-right-bottom
  .settings-tab-result {
  border-left: 1px solid #ececec;
  flex-basis: 100%;
  padding: 0 1rem;
  padding-top: 1rem;
}

@media only screen and (max-width: 1040px) {
  .settings-container .settings-right .settings-right-bottom {
    flex-direction: column;
    gap: 25px;
  }

  .settings-container .settings-right .settings-right-bottom .settings-options {
    display: flex;
    flex-direction: row;
    gap: 7px;
  }

  .settings-container
    .settings-right
    .settings-right-bottom
    .settings-tab-result {
    border-left: none;
    flex-basis: 100%;
    padding: 0;
    margin-top: 1.5rem;
  }
}

@media only screen and (max-width: 1000px) {
  .settings-container .settings-right {
    margin-top: 3rem;
  }

  .settings-container .settings-right .settings-right-bottom {
    margin: 0.5rem 0;
  }
}

@media only screen and (max-width: 920px) {
  .settings-container .settings-right .settings-right-bottom .settings-options {
    padding: 0.5rem;
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .settings-container
    .settings-right
    .settings-right-bottom
    .settings-options
    span {
    padding: 0;
  }

  .settings-container
    .settings-right
    .settings-right-bottom
    .settings-options
    .settings-active {
    padding: 8px 10px;
  }

  .settings-container .settings-left {
    display: none;
  }

  .settings-container .settings-right {
    padding: 1rem 0.7rem;
    width: 100%;
  }

  .settings-container .settings-right .settings-right-bottom {
    padding: 0.3rem 0.2rem;
  }

  .settings-container
    .settings-right
    .settings-right-bottom
    .settings-tab-result {
    padding: 0 0.5rem;
  }
}

@media only screen and (max-width: 750px) {
  .settings-container .settings-right h3 {
    margin: 1rem 0 0.2rem;
    color: #465174;
    font-size: 28px;
    padding: 1rem 0;
  }
}

@media only screen and (max-width: 500px) {
  .settings-container .settings-right .settings-right-bottom .settings-options {
    gap: 15px;
  }

  .settings-container
    .settings-right
    .settings-right-bottom
    .settings-options
    span {
    border-radius: 10px;
    font-weight: 500;
    line-height: 18px;
    font-size: 14px;
    display: block;
    width: fit-content;
  }

  .settings-container
    .settings-right
    .settings-right-bottom
    .settings-options
    .settings-active {
      padding: 6px 8px;
  }
}
