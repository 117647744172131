.filter-box-container .filters-box-wrapper {
  width: auto;
  padding: 1rem 2rem 3rem;
  background-color: #fff;
  border-radius: 8px;
}

.filter-box-container .filters-box-wrapper .filter-header {
  margin: 1.4rem 0;
}

.filter-box-container .filters-box-wrapper .jobListings-divisions {
  margin-top: 0.9rem;
  margin-bottom: 1.8rem;
}

.filter-box-container .filters-box-wrapper .jobListings-divisions h4 {
  color: #245293;
}

.filter-box-container .filters-box-wrapper select {
  padding: 0.4rem 0.5rem;
  width: 100%;
  border: 1px solid #c5d4e7;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
}

.filter-box-container .filters-box-wrapper .jobListings-location {
  margin-top: 0.9rem;
  margin-bottom: 1.8rem;
}

.filter-box-container .filters-box-wrapper .jobListings-location h4 {
  color: #245293;
}

.filter-box-container .filters-box-wrapper .jobListings-salary {
  margin-top: 0.9rem;
  margin-bottom: 1.8rem;
}

.filter-box-container .filters-box-wrapper .column {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.filter-box-container .filters-box-wrapper .column .top {
  margin: 0.3rem 0;
}

.filter-box-container .filters-box-wrapper .column h4 {
  color: #245293;
  line-height: 24px;
  font-weight: 600;
  font-size: 16px;
}

.filter-box-container .filters-box-wrapper .jobListings-topSalary {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 0.5rem 0;
}

.filter-box-container .filters-box-wrapper .jobListings-topSalary .flex-input {
  display: flex;
  align-items: center;
  gap: 15px;
}

.filter-box-container .filters-box-wrapper .full-auto-complete {
  margin-top: 0.5rem;
  width: 100%;
  display: flex;
  gap: 1rem;
}

.filter-box-container .filters-box-wrapper .full-auto-complete .loc-first {
  flex: 1;
}

.filter-box-container
  .filters-box-wrapper
  .jobListings-topSalary
  .flex-input
  .icon {
  color: #c5d4e7;
}

.filter-box-container
  .filters-box-wrapper
  .jobListings-topSalary
  .flex-input
  .date-filter-jobs {
  flex: 1;
  width: 100%;
}

.filter-box-container .filters-box-wrapper .reset-filters {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}

@media only screen and (max-width: 1400px) {
  .filter-box-container
    .filters-box-wrapper
    .jobListings-topSalary
    .flex-input {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

@media only screen and (max-width: 1000px) {
  .filter-box-container .filters-box-wrapper {
    padding: 1rem 1.2rem 3rem;
    background-color: #fff;
  }
}

@media only screen and (max-width: 900px) {
  .filter-box-container
    .filters-box-wrapper
    .jobListings-topSalary
    .flex-input {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 15px;
  }
}

@media only screen and (max-width: 500px) {
  .filter-small-screen .filter-box-container .filters-box-wrapper {
    width: 370px;
    padding: 1rem 1rem;
    background-color: #fff;
    border-radius: 8px;
  }
}
