.exploreDivisions-container {
    width: 85%;
    margin: 0 auto;
    padding: 2rem;
    background-color: #fff;
    margin-bottom: 35px;
    border-radius: 10px;
    margin-top: 2rem;
}

.exploreDivisions-container h3 {
    text-align: center;
    font-size: 32px;
    font-weight: 800;
    margin-bottom: 19px;
    color: #245293;
}

.exploreDivisions-container .exploreDivisions-text {
    width: 67%;
    margin: 0 auto;
    text-align: center;
    color: #245293;
    line-height: 1.6;
}

.exploreDivisions-boxes-container{
    margin-top: 20px;
    padding: 3rem 1rem;
    display: flex;
    justify-content: center;
    width: 90%;
    margin: 0 auto;
    flex-wrap: wrap;
    gap: 1rem;
}

.exploreDivisions-container .exploreDivisions-boxes-container .exploreDivisions-box {
    padding: 30px;
    width: 30%;
}

.exploreDivisions-container .exploreDivisions-boxes-container h5 {
    margin: 10px 0;
    font-size: 20px;
}

.exploreDivisions-container .exploreDivisions-boxes-container .square-green{
    color: #36A695;
    font-weight: 700;
}

.exploreDivisions-container .exploreDivisions-boxes-container .square-orange{
    color: #F5A656;
    font-weight: 700;
}

.exploreDivisions-container .exploreDivisions-boxes-container .square-red {
    color: #DB3E4D;
    font-weight: 700;
}

.exploreDivisions-container .exploreDivisions-boxes-container .exploreDivisions-box p {
    color: #808080;
    font-size: 17px;
}

@media only screen and (max-width: 1250px) {
    .exploreDivisions-container {
        width: 90%;
    }

    .exploreDivisions-container .exploreDivisions-boxes-container .exploreDivisions-box {
        padding: 15px;
        width: 30%;
    }
}


@media only screen and (max-width: 900px) {
    .exploreDivisions-container {
        width: 95%;
        padding: 2rem 0.4rem;
    }    

    .exploreDivisions-container .exploreDivisions-text {
        width: 90%;
        text-align: center;
    }
    .exploreDivisions-boxes-container{
        padding: 2rem 1rem;
        width: 100%;
        flex-wrap: wrap;
    }

    .exploreDivisions-container .exploreDivisions-boxes-container .exploreDivisions-box {
        padding: 30px 10px;
        width: 360px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .exploreDivisions-container .exploreDivisions-boxes-container .exploreDivisions-box p {
        width: 65%;
        text-align: center;
    }
}

@media only screen and (max-width: 800px) {
    .exploreDivisions-container .exploreDivisions-boxes-container .exploreDivisions-box {
        width: 100%;
    }

    .exploreDivisions-container .exploreDivisions-boxes-container .exploreDivisions-box p {
        width: 85%;
        text-align: center;
    }
}