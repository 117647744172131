.jobDetails-container {
  padding-top: 150px;
  width: 85%;
  margin: 0 auto;
  min-height: 100vh;
}

.loading-screen-jobs {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.jobDetails-container .jobDetails-top {
  display: flex;
  justify-content: space-between;
  padding-top: 1.5rem;
}

.jobDetails-container .jobDetails-top .jobDetails-desc {
  min-width: 30%;
}

.jobDetails-container .jobDetails-top .jobDetails-desc h3 {
  font-size: 1.15rem;
  color: #245293;
  text-transform: uppercase;
}

.jobDetails-container .jobDetails-top .jobDetails-desc .overall-special {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 1.5rem;
}

.between {
  justify-content: space-between;
}

.jobDetails-container .jobDetails-top .jobDetails-desc .specialty {
  display: flex;
  align-items: center;
  gap: 10px;
}

.jobDetails-container
  .jobDetails-top
  .jobDetails-desc
  .specialty
  .special-text {
  font-weight: 600;
  color: "#000";
  margin-left: auto;
  text-transform: capitalize;
}

.jobDetails-container
  .jobDetails-top
  .jobDetails-desc
  .specialty
  .jobDetails-icon {
  color: #245293;
}

.jobDetails-container .jobDetails-top .jobDetails-desc .specialty span {
  font-size: 18px;
  color: #808080;
}

.jobDetails-container .jobDetails-top .jobDetails-apply .findJobs-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 260px;
}

.jobDetails-container
  .jobDetails-top
  .jobDetails-apply
  .findJobs-button
  button {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
}

.jobDetails-container .jobDetails-bottom {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 2rem;
}

.jobDetails-container
  .jobDetails-bottom
  .jobDetails-requirements
  .requirements {
  margin: 2rem 0;
}

.jobDetails-container .jobDetails-bottom .jobDetails-requirements p {
  font-size: 1.2rem;
  color: #808080;
  margin-bottom: 1.5rem;
  text-wrap: wrap;
}

.jobDetails-container
  .jobDetails-bottom
  .jobDetails-requirements
  .requirements
  h3 {
  font-size: 1.15rem;
  color: #245293;
}

.jobDetails-container
  .jobDetails-bottom
  .jobDetails-requirements
  .requirements
  ul {
  width: 60%;
  margin: 0 auto;
  font-size: 18px;
  color: #808080;
  margin: 0.5rem 0;
  padding: 0 1rem;
  list-style: disc;
}

.jobDetails-container
  .jobDetails-bottom
  .jobDetails-requirements
  .requirements
  li {
  margin: 1rem 0;
}

.jobDetails-container .candidates-result {
  padding-bottom: 2rem;
}

.jobDetails-container .candidates-result h3 {
  font-size: 1.15rem;
  color: #245293;
}

.jobDetails-container .candidates-result .candidates-result-headings {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 1rem 0 1rem 1.5rem;
  margin-top: 1rem;
  border-bottom: 1px solid #000;
}

.jobDetails-container .candidates-result .candidates-result-headings span {
  width: 15%;
  color: #1c2533;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
}

.jobDetails-container
  .candidates-result
  .candidates-result-box
  .single-candidate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0 1rem 1.5rem;
  background-color: #fff;
  border-bottom: 1px solid #d8dde5;
}

.jobDetails-container
  .candidates-result
  .candidates-result-box
  .single-candidate
  .single {
  width: 15%;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}

.jobDetails-container
  .candidates-result
  .candidates-result-box
  .single-candidate
  .single
  .mobile-time {
  display: none;
}

@media only screen and (max-width: 1000px) {
  .dashboard-modal-box
    .dashboard-modal-content
    .dashboard-modal
    .box-timesheet {
    padding: 0rem;
  }

  .jobDetails-container .candidates-result .candidates-result-box {
    border: none;
    margin-top: 1rem;
  }
  .jobDetails-container .candidates-result .candidates-result-headings {
    display: none;
  }

  .jobDetails-container
    .candidates-result
    .candidates-result-box
    .single-candidate {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1rem 1rem 1rem 1rem;
    border-bottom: 1px solid #d8dde5;
    gap: 1rem;
  }

  .jobDetails-container
    .candidates-result
    .candidates-result-box
    .single-candidate
    .single {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
  }

  .jobDetails-container
    .candidates-result
    .candidates-result-box
    .single-candidate
    .single
    .mobile-time {
    display: block;
    font-weight: 700;
  }
}

@media only screen and (max-width: 700px) {
  .jobDetails-container .jobDetails-top {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-top: 1.5rem;
    gap: 1rem;
  }

  .jobDetails-container .jobDetails-top .jobDetails-apply .findJobs-button {
    width: 100%;
  }

  .jobDetails-container
    .jobDetails-bottom
    .jobDetails-requirements
    .requirements
    ul {
    width: 100%;
    margin: 0 auto;
    font-size: 18px;
    color: #808080;
    margin: 0.5rem 0;
    padding: 0 1rem;
    list-style: disc;
  }
}
