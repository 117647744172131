.jobs-diagram {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 10px;
}

.jobs-diagram p {
  color: #245293;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
}

.jobs-diagram .broad {
  width: 100%;
  border-radius: 20px;
  height: 10px;
  display: flex;
  align-items: center;
}

.jobs-diagram .broad .apply {
  width: 50%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: #4339f2;
  height: 100%;
}

.jobs-diagram .broad .view {
  width: 50%;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #ffb200;
  height: 100%;
}

.jobs-diagram .below-apply {
  display: flex;
  align-items: center;
  width: 100%;
}

.jobs-diagram .below-apply .each-apply {
  width: 50%;
}

.jobs-diagram .below-apply .each-apply p {
  color: #808080;
  font-size: 12px;
  font-weight: 600;
}

.jobs-diagram .below-apply .each-apply .icon-apply {
  margin-top: 5px;
  display: flex;
  gap: 5px;
  align-items: center;
}

.jobs-diagram .below-apply .each-apply .icon-apply p {
  color: #808080;
  font-size: 16px;
  font-weight: 600;
}
