.job-result-box {
  border: 1px solid rgb(240, 240, 240);
  padding: 1rem;
  border-radius: 1rem;
  background: #fff;
  box-shadow: 4px 12px 30px 0px rgba(0, 0, 0, 0.08);
  position: relative;
}

.job-result-box .job-box-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.job-result-box .job-box-top h5 {
  font-size: 18px;
  font-weight: 600;
  color: #3a649e;
  text-transform: capitalize;
}

.job-result-box .job-box-top .job-rate {
  font-size: 11px;
  font-weight: 800;
  border-radius: 4px;
  background: #36a695;
  color: #fff;
  padding: 5px;
}

.job-result-box .job-location {
  margin-top: 2px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 2px 0;
}

.job-result-box .job-location-icon {
  font-size: 18px;
  color: #3a649e;
}

.job-result-box .job-location-text {
  color: #7e9ac0;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.job-result-box .job-desc-box {
  height: 100px;
}

.job-result-box .job-desc-box .job-description {
  color: #808080;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 10px 0 15px;
}

.job-result-box .job-app {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}

.job-result-box .job-app button {
  background-color: transparent;
  font-size: 14px;
  color: #3a649e;
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.job-result-box .job-result-bottom {
  display: flex;
  align-items: center;
  padding: 10px 0 0;
  gap: 12px;
  border-top: 1.2px solid #c4e4f5;
}

.job-result-box .job-result-bottom .job-result-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.job-result-box .job-result-bottom .job-result-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.job-result-box .job-result-bottom .job-result-others h6 {
  font-size: 16px;
  color: #3a649e;
  font-weight: 600;
}

.job-result-box .job-item-review-timesheet {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 0.5rem 0;
}

.job-result-box .job-item-review-timesheet .timesheet-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 16px;
  padding: 0.5rem 0.2rem;
  width: 170px;
  border-radius: 15px;
  background-color: #3a649e;
  color: #fff;
  cursor: pointer;
}

.job-result-box .job-item-review-timesheet button {
  border: none;
}

.job-result-box .job-item-review-timesheet .review-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 16px;
  width: 170px;
  border-radius: 15px;
  border: 1px solid #3a649e;
  background-color: #fff;
  color: #3a649e;
  font-weight: 700;
  cursor: pointer;
}

.job-result-box .jobitem-hr {
  margin: 1rem 0;
  background-color: rgb(240, 240, 240);
}

.job-result-box .hover-state {
  background-color: black;
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 1rem;
}

@media only screen and (max-width: 1440px) {
  .job-result-box .job-box-top h5 {
    font-size: 16px;
  }

  .job-result-box .job-box-top .job-rate {
    font-size: 11px;
  }

  .job-result-box .job-description {
    font-size: 13px;
    margin: 12px 0;
    line-height: 1.4;
  }

  .job-result-box .job-location-text {
    font-size: 13px;
  }

  .job-result-box .job-result-bottom .job-result-image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
  }

  .job-result-box .job-result-bottom .job-result-others h6 {
    font-size: 14px;
  }
}

@media only screen and (max-width: 750px) {
  .job-result-box {
    padding: 1rem;
  }
}
