.btn-custom {
  width: 100%;
  border-radius: 30px;
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 1rem;
  height: 45px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6d96ce;
}