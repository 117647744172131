.customInput-contain {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.customInput-contain input {
  padding-left: 15px;
  border-radius: 100px;
  border: 1px solid #efefef;
  background: #fff;
  width: 50%;
  height: 48px;
  outline: none;
}

@media only screen and (max-width: 600px) {
  .customInput-contain input {
    border-radius: 50px;
    width: 100%;
    height: 50px;
  }
}
