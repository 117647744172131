.about-main {
    width: 100%;
    border-radius: 19px;
    padding: 3rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-main .about-main-content {
    width: 70%;
    margin: 120px auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.about-main .about-main-content h1 {
    font-size: 48px;
    font-weight: 800;
    line-height: 58px;
    color:#245293;
}


@media only screen and (max-width: 1350px) {
    .about-main .about-main-content {
        width: 85%;
    }
    
    .about-main .about-main-content h1 {
        font-size: 35px;
    }
}

@media only screen and (max-width: 900px) {
    .about-main {
        width: 95%;
    }

    .about-main .about-main-content h1 {
        font-size: 32px;
        line-height: 40px;
    }
}