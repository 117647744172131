.postjob-container {
  margin: 1rem 1rem;
}

.toast-msg {
  text-transform: capitalize;
  font-weight: 600;
}

.postjob-container h4 {
  color: #465174;
  font-size: 24px;
  font-weight: 700;
  line-height: 64px;
}

.postjob-container p {
  color: #777;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.postjob-container .postjob-content {
  margin: 1rem 0;
  border-radius: 24px;
  background: #fff;
  padding: 1rem 2rem 1.5rem;
}

.postjob-container .postjob-content .inner-box {
  display: flex;
  gap: 1rem;
  padding: 2.5rem 0;
  border-bottom: 1px solid #efefef;
}

.postjob-container .postjob-content .inner-box .first-box {
  width: 35%;
}

.postjob-container .postjob-content .inner-box .first-box h5 {
  color: #245293;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.postjob-container .postjob-content .inner-box .first-box p {
  margin-top: 0.5rem;
  color: #777;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.postjob-container .postjob-content .inner-box .second-box {
  width: 65%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.postjob-container .postjob-content .inner-box .second-box .inner-desc {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.postjob-container
  .postjob-content
  .inner-box
  .second-box
  .inner-desc.desc-text {
  color: #777;
  font-size: 14px;
  font-weight: 500;
}

.postjob-container
  .postjob-content
  .inner-box
  .second-box
  .inner-desc
  textarea {
  border-radius: 30px;
  border: 1px solid #efefef;
  background: #fff;
  height: 50px;
  padding: 1rem;
  outline: none;
  color: #777;
  font-size: 14px;
  font-weight: 500;
  height: 100px;
  color: #000;
}

.postjob-container .postjob-content .inner-box .second-box button {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  background-color: #245293;
  border: none;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 5px;
}

.postjob-container .postjob-content .inner-box .second-box .min-box {
  min-height: 150px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.postjob-container .postjob-content .inner-box .second-box .min-box .no-res {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.postjob-container .postjob-content .inner-box .second-box .min-box .res-item {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.postjob-container
  .postjob-content
  .inner-box
  .second-box
  .min-box
  .res-item
  .res-item-content {
  display: flex;
  /* align-items: center; */
  gap: 10px;
  width: 90%;
}

.postjob-container
  .postjob-content
  .inner-box
  .second-box
  .min-box
  .res-item
  .bg-dot {
  width: 6%;
  padding-top: 7px;
}

.postjob-container
  .postjob-content
  .inner-box
  .second-box
  .min-box
  .res-item
  .res-item-words {
  width: 94%;
}

.postjob-container
  .postjob-content
  .inner-box
  .second-box
  .min-box
  .res-item
  .res-bg-icon {
  width: 10%;
}

.postjob-container
  .postjob-content
  .inner-box
  .second-box
  .min-box
  .res-item
  .res-bg-icon
  .icon-btn-del {
  cursor: pointer;
  color: red;
}

.postjob-container
  .postjob-content
  .inner-box
  .second-box
  .min-box
  .res-item
  p {
  font-size: 14px;
  font-weight: 600;
}

.postjob-container
  .postjob-content
  .inner-box
  .second-box
  .min-box
  .res-item
  p::first-letter {
  text-transform: capitalize;
}

.postjob-container
  .postjob-content
  .inner-box
  .second-box
  .min-box
  .res-item
  .dot-num {
  background-color: #000;
  height: 8px;
  width: 8px;
  border-radius: 50%;
}

.postjob-container .postjob-content .inner-box .second-box .desc-text {
  color: #777;
  font-size: 14px;
  font-weight: 500;
}

.postjob-container .postjob-content .inner-box .second-box textarea {
  border-radius: 10px;
  border: 1px solid #efefef;
  background: #fff;
  height: 50px;
  padding: 1rem;
  outline: none;
  color: #777;
  font-size: 14px;
  font-weight: 500;
}

.postjob-container .postjob-content .inner-box .second-box .inner-input-1 {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.postjob-container .postjob-content .inner-box .second-box-list {
  display: flex;
  flex-wrap: wrap;
  width: 65%;
  row-gap: 2rem;
}

.postjob-container
  .postjob-content
  .inner-box
  .second-box-list
  .item
  .checkbox {
  width: 20px;
  height: 20px;
  border-radius: 40px;
}

.postjob-container .postjob-content .inner-box .second-box-list .item span {
  color: #1c2533;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.postjob-container
  .postjob-content
  .inner-box
  .second-box
  .inner-input-1
  .auto-complete-con {
  width: 50%;
}

.postjob-container .postjob-content .btn-bg {
  padding: 2rem 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  margin-left: auto;
}

.postjob-container .postjob-content .btn-bg .btn {
  width: 20%;
}

@media only screen and (max-width: 1000px) {
  .postjob-container {
    margin: 6rem 1rem 1rem;
  }

  .postjob-container .postjob-content {
    border-radius: 18px;
    padding: 0.5rem 1rem 1rem;
  }
}

@media only screen and (max-width: 750px) {
  .postjob-container .postjob-content .inner-box {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2.5rem 0;
    border-bottom: 1px solid #efefef;
  }

  .postjob-container .postjob-content .inner-box .first-box {
    width: 50%;
  }

  .postjob-container .postjob-content .inner-box .second-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .postjob-container .postjob-content .inner-box .second-box-list {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .postjob-container {
    margin: 6rem 0.5rem 1rem;
  }
  .postjob-container .postjob-content .inner-box .first-box {
    width: 100%;
  }

  .postjob-container .postjob-content .inner-box .second-box .inner-input-1 {
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
  }

  .postjob-container
    .postjob-content
    .inner-box
    .second-box
    .inner-input-1
    .auto-complete-con {
    width: 100%;
  }

  .postjob-container .postjob-content .inner-box .second-box-list {
    justify-content: space-between;
    row-gap: 2rem;
  }

  .postjob-container .postjob-content .inner-box .second-box-list .item {
    gap: 0.5rem;
    width: 45%;
  }

  .postjob-container .postjob-content .btn-bg {
    padding: 2rem 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .postjob-container .postjob-content .btn-bg .btn {
    width: 100%;
  }
}
