.singleApplicantPageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.singleApplicantPageContainer .icon-can {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  cursor: pointer;
  color: gray;
}

.singleApplicantPageContainer .single-applicant-page-content {
  padding: 1rem;
  border-radius: 24px;
  background: #fff;
  width: 100%;
}

.singleApplicantPageContainer .single-applicant-page-content .page-content-top {
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 1rem 0;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-top
  .top-image {
  border-radius: 50px;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-top
  .top-image
  img {
  border-radius: 50px;
  height: 100px;
  width: 100px;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-top
  .top-name {
  display: flex;
  gap: 7px;
  align-items: center;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-top
  .top-name
  .status.verified {
  color: #245293;
  background: #eef7ff;
  padding: 2px 6px;
  border-radius: 9px;
  font-size: 15px;
  font-weight: 600;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-top
  .top-email {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-top
  .top-email
  .dot {
  color: white;
  font-size: 16px;
  font-weight: 600;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-top
  .top-email
  p {
  color: #808080;
  font-size: 14px;
  font-weight: 400;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .content {
  display: flex;
  gap: 0.5rem;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .content
  .box {
  border: 1px solid #dddddd;
  border-radius: 0.5rem;
  min-height: 150px;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .content
  .box
  .upper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dddddd;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .content
  .box
  .middle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .content
  .box
  .upper-inner {
  border-right: 1px solid #dddddd;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .content
  .box
  .space {
  padding: 1rem;
  flex: 1;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .content
  .box
  .space-2 {
  padding: 1rem;
  width: 80%;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .content
  .box
  .space-2
  .loc-states {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 1rem;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .content
  .box
  .space-2
  .loc-states
  .loc-states-each {
  border-radius: 2px;
  border: 1px solid rgb(160, 160, 160);
  background-color: #fffdfd;
  color: #606060;
  font-size: 16px;
  font-weight: 500;
  width: fit;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .content
  .box
  .space-2
  .loc-states
  .loc-states-each
  .main {
  padding: 5px 7px;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .content
  .box
  p {
  color: rgba(128, 128, 128, 1);
  font-size: 15px;
  font-weight: 400;
  line-height: 18.5px;
  margin-top: 0.2rem;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .content
  .box
  h6 {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  color: rgba(0, 0, 0, 1);
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .content
  .box
  .inner-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1.5rem;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .content
  .box
  .inner-box
  .link-inner {
  display: flex;
  align-items: flex-end;
  gap: 0.5rem;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .content
  .box
  .inner-box
  .link-inner
  p {
  font-size: 16px;
  line-height: 19.36px;
  font-weight: 400;
  text-decoration: underline;
  color: rgba(36, 82, 147, 1);
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .content
  .wider {
  width: 70%;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .content
  .smaller {
  width: 30%;
  padding: 1rem;
}

@media only screen and (max-width: 600px) {
  .singleApplicantPageContainer
    .single-applicant-page-content
    .page-content-bottom
    .content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .singleApplicantPageContainer
    .single-applicant-page-content
    .page-content-bottom
    .content
    .wider {
    width: 100%;
  }

  .singleApplicantPageContainer
    .single-applicant-page-content
    .page-content-bottom
    .content
    .box {
    width: 100%;
  }
}
