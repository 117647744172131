.box-analysis h4 {
    color: #465174;
    font-size: 32px;
    font-weight: 600;
}

.box-analysis span {
    color: #8C8C8C;
    font-size: 16px;
    font-weight: 500;
}

.box-analysis {
    width: 33%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 25px;
    background-color: #a2f5e8;
    border-radius: 8px;
}

.box-analysis-icon {
    font-size: 28px;
    color: #00B297;
}

@media only screen and (max-width: 1200px) {
    .box-analysis h4 {
        font-size: 28px;
    }

    .box-analysis span {
        font-size: 14px;
    }

}

@media only screen and (max-width: 700px) {
    .box-analysis h4 {
        font-size: 26px;
    }

    .box-analysis span {
        font-size: 12px;
    }

    .box-analysis {
        width: 100%;
    }
}