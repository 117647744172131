@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");

.nav-container-das {
  width: 100%;
  position: fixed;
  top: 0;
  margin-bottom: 20px;
  z-index: 50;
}

.header-das .mobile-nav {
  display: flex;
  align-items: center;
  gap: 30px;
}

.nav-container-das .nav_title {
  width: 60px;
  height: 55px;
  object-fit: cover;
  z-index: 9999;
}

.nav-container-das .nav_title img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.header-das {
  width: 95%;
  margin: 20px auto 20px;
  border: 1px solid #d3c5c5;
  z-index: 100;
  background-color: #fff;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
}

.header-das .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: #212121;
  opacity: 1;
  display: block;
  font-size: 24px;
}

.header-das nav {
  position: fixed;
  top: 0;
  padding: 7rem 1rem 1rem 1rem;
  right: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: white;
  color: black;
  transition: 1s;
  box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.75);
  transform: translateX(150vh);
}

.nav-container-das nav .nav-bar-con {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
}

.nav-container-das nav .nav-bar-con .first-items {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  justify-items: flex-start;
}

nav a {
  border-bottom: none;
  padding: auto 1rem;
}

nav .profile-button {
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

nav .profile-active {
  color: #245293;
}

nav .profile-dom {
  color: #808080;
}

nav .profile-dom:hover {
  background-color: #f4faff;
}

nav .profile-button span {
  font-size: 16px;
  line-height: 19px;
}

nav .box-2 {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  position: absolute;
  bottom: 1rem;
  left: 0;
  right: 0;
}

nav .box-2 .first {
  display: flex;
  gap: 1rem;
  align-items: center;
}

nav .box-2 .profile-pics {
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

nav .box-2 .box-2-text {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  align-items: flex-start;
}

nav .box-2 .box-2-text span {
  color: #000000;
  font-size: 15px;
  font-weight: 600;
  line-height: 19px;
  text-transform: uppercase;
}

nav .box-2 .box-2-text h5 {
  color: #5372ea;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
  margin-top: 3px;
}

.header-das .responsive_nav_das {
  transform: none;
}

.header-das .nav-close-btn {
  margin-top: 7px;
  font-size: 24px;
  color: black;
}

nav .nav-close-btn {
  position: absolute;
  top: 1.5rem;
  right: 1rem;
  font-size: 1.8rem;
  font-weight: 100;
}
