.input-box-1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    border: 1px solid  #efefef;
    width: auto;
    padding: 12px 10px;
}

.input-box-1 input {
    width: 70%;
    outline: none;
    border: none;
    border-radius: 4px;
}