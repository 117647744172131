.loading-screen-jobs {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.pag-right {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 2rem;
  margin-right: 1rem;
}

.pag-screen {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}