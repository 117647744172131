.job-box {
  border-radius: 13.057px;
  border: 0.816px solid rgba(151, 173, 204, 0.15);
  padding: 1rem;
  position: relative;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.08);
  position: relative;
  overflow: hidden;
}

.job-box .flex-head {
  display: flex;
  align-items: center;
  gap: 13px;
}

.job-box .flex-head img {
  width: 39.17px;
  height: 39.17px;
  border: 20px;
}

.job-box .flex-head h3 {
  font-size: 13px;
  font-weight: 600;
  color: #3a649e;
  text-transform: capitalize;
}

.job-box .flex-head .div-locate {
  margin-top: 0.3rem;
  display: flex;
  align-items: center;
  font-size: 11px;
  color: #7e9ac0;
  gap: 2px;
}

.job-box .second-section {
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.job-box .second-section .flex-second {
  display: flex;
  justify-content: space-between;
}

.job-box .second-section .flex-second h3 {
  color: #3a649e;
  font-size: 16.321px;
  font-weight: 700;
  line-height: 24.481px;
  text-transform: capitalize;
}

.job-box .second-section .flex-second span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 53.042px;
  height: 22.033px;
  border-radius: 6.528px;
  background: #36a695;
  font-size: 9.792px;
  font-weight: 600;
  line-height: 11.425px;
  color: #fff;
}

.job-box .second-section .flex-second-dec {
  height: 100px;
}

.job-box .second-section .flex-second-dec p {
  color: #808080;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin: 10px 0 15px;
}

.job-box .third-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-top: 1.1rem;
}

.job-box .third-section .btn-one {
  padding: 8px auto;
  width: 135px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #fff;
  font-size: 13.057px;
  font-weight: 600;
  line-height: 24.481px;
  background-color: #3a649e;
  border-radius: 20px;
  border: 1px solid #3a649e;
  cursor: pointer;
}

.job-box .third-section .btn-two {
  padding: 8px auto;
  width: 135px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #3a649e;
  font-size: 13.057px;
  font-weight: 600;
  line-height: 24.481px;
  background-color: #fff;
  border-radius: 20px;
  border: 1px solid #3a649e;
  cursor: pointer;
}

.job-box .status {
  position: absolute;
  transform: rotate(-35deg);
  top: 10px;
  left: -30px;
  width: 120.806px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.job-box .status span {
  color: #fff;
  font-size: 9px;
  font-weight: 800;
  margin-right: 9px;
}

.timesheet-con {
  padding: 1rem;
}

.timesheet-con h3 {
  font-size: 1.15rem;
  color: #245293;
  text-align: center;
}

.timesheet-con .timesheet-headings {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #e2e2e2;
  padding: 1rem 0 1rem 1.5rem;
  margin-top: 1rem;
}

.timesheet-con .timesheet-headings span {
  width: 15%;
  color: #1c2533;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
}

.timesheet-con .timesheet-result-box {
  height: 300px;
  overflow-y: scroll;
}

.timesheet-con .timesheet-result-box .single-candidate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0 1rem 1.5rem;
  background-color: #fff;
  border-bottom: 1px solid #d8dde5;
}

.timesheet-con .timesheet-result-box .single-candidate .single {
  width: 15%;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}

.timesheet-con .timesheet-result-box .single-candidate .single span {
  font-weight: 700;
}

.timesheet-con .timesheet-result-box .single-candidate .single .mobile-time {
  display: none;
}

.third-section .left {
  margin-left: auto;
}

.timesheet-con .timesheet-footer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 2rem;
}

.timesheet-con .timesheet-footer .timesheet-btn {
  width: 100px;
}

@media only screen and (max-width: 1000px) {
  .timesheet-con {
    padding: 0rem;
  }
  .timesheet-con .timesheet-result-box {
    border: none;
    margin-top: 1rem;
    height: 300px;
    overflow-y: scroll;
    margin-right: -1.5rem;
  }
  .timesheet-con .timesheet-headings {
    display: none;
  }

  .timesheet-con .timesheet-result-box .single-candidate {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1rem 0rem 1rem;
    border-bottom: 1px solid #d8dde5;
    gap: 1rem;
    margin-right: 1.2rem;
  }

  .timesheet-con .timesheet-result-box .single-candidate .single {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
  }

  .timesheet-con .timesheet-result-box .single-candidate .single .mobile-time {
    display: block;
  }
}
