.paymentcard-container {
  display: flex;
  padding: 1.5rem 1rem;
  align-items: center;
  gap: 1rem;
  width: 35%;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 4px 6px 12px 0px rgba(36, 82, 147, 0.04);
}

.paymentcard-container .image-bg {
  padding: 10px;
  border-radius: 50%;
  background-color: hsla(196, 100%, 98%, 1);
}

.paymentcard-container .text-bg {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.paymentcard-container .text-bg p {
  color: #808080;
  font-size: 18px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: -0.5px;
}

.paymentcard-container .text-bg h5 {
  color: #245293;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: -1px;
}

@media only screen and (max-width: 800px) {
  .paymentcard-container {
    padding: 1rem 0.2rem;
    border-radius: 8px;
  }

  .paymentcard-container .text-bg p {
    font-size: 14px;
  }
  
  .paymentcard-container .text-bg h5 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 650px) {
  .paymentcard-container {
    padding: 1rem;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 4px 6px 12px 0px rgba(36, 82, 147, 0.04);
  }
}
