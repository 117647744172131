.goal-card {
    height: 120px;
    width: 25%;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 3px 7px -6px rgba(20,19,19,0.88)
}

.goal-card p {
    padding-top: 5px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #fff
}

@media only screen and (max-width: 1350px) {
    .goal-card p {
        padding-top: 10px;
        font-size: 14px;
        line-height: 18px;
    }
}

@media only screen and (max-width: 750px) {
    .goal-card {
        width: 45%;
    }
}


@media only screen and (max-width: 900px) {
    .goal-card p {
        padding-top: 20px;
        font-size: 14px;
        line-height: 18px;
    }
}