.shortlist-container .shortlist-right {
  width: 100%;
  padding: 0rem 1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.shortlist-container .shortlist-right .shortlist-right-bottom h2 {
  font-size: 24px;
  color: #465174;
}

.shortlist-container
  .shortlist-right
  .shortlist-right-bottom
  .shortlist-header {
  margin: 0 0 1.2rem;
}

.shortlist-container
  .shortlist-right
  .shortlist-right-bottom
  .shortlist-header-below {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  background-color: #fff;
  margin-bottom: 1.5rem;
  padding: 0.5rem;
}

.shortlist-container
  .shortlist-right
  .shortlist-right-bottom
  .shortlist-header-below
  .shortist-auto-complete {
  flex: 1;
}

.shortlist-container
  .shortlist-right
  .shortlist-right-bottom
  .shortlist-header-below
  .shortlist-dates {
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: #fff;
  padding: 0 20px;
  border: 1px solid #efefef;
  justify-content: space-between;
}

.shortlist-container
  .shortlist-right
  .shortlist-right-bottom
  .shortlist-header-below
  .shortlist-dates
  span {
  color: #465174;
}

.shortlist-container
  .shortlist-right
  .shortlist-right-bottom
  .shortlist-header-below
  .shortlist-dates
  .date-listing {
  width: 45%;
}

.shortlist-container
  .shortlist-right
  .shortlist-right-bottom
  .shortlist-right-content {
  background-color: #fff;
  border-radius: 7px;
  padding: 2rem 0;
}

.shortlist-container
  .shortlist-right
  .shortlist-right-bottom
  .shortlist-right-content
  .job-list-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.shortlist-container
  .shortlist-right
  .shortlist-right-bottom
  .shortlist-right-content
  .job-list-container
  .shortlist-jobItem {
  width: 30%;
  cursor: pointer;
}

@media only screen and (max-width: 1300px) {
  .shortlist-container
    .shortlist-right
    .shortlist-right-bottom
    .shortlist-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-items: flex-start;
    gap: 1rem;
  }
}

@media only screen and (max-width: 1200px) {
  .shortlist-container
    .shortlist-right
    .shortlist-right-bottom
    .shortlist-right-content
    .job-list-container
    .shortlist-jobItem {
    width: 45%;
  }
}

@media only screen and (max-width: 1100px) {
}

@media only screen and (max-width: 1000px) {
  .shortlist-container .shortlist-right {
    margin-top: 7rem;
  }
}

@media only screen and (max-width: 800px) {
  .shortlist-container
    .shortlist-right
    .shortlist-right-bottom
    .shortlist-header-below {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    background-color: #465174;
    margin-bottom: 1.5rem;
    padding: 1.2rem 1rem;
  }

  .shortlist-container
    .shortlist-right
    .shortlist-right-bottom
    .shortlist-header-below
    .shortist-auto-complete {
    width: 100%;
  }

  .shortlist-container
    .shortlist-right
    .shortlist-right-bottom
    .shortlist-header-below
    .shortlist-dates {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 5px 5px;
    justify-content: space-between;
  }

  .shortlist-container
    .shortlist-right
    .shortlist-right-bottom
    .shortlist-header-below
    .shortlist-dates
    span {
    display: none;
  }
}

@media only screen and (max-width: 750px) {
  .shortlist-container .shortlist-left {
    display: none;
  }

  .shortlist-container .shortlist-right-top {
    display: none;
  }

  .shortlist-container
    .shortlist-right
    .shortlist-right-bottom
    .shortlist-header
    .shortlist-header-right
    .shortist-auto-complete {
    width: 100%;
  }

  .shortlist-container
    .shortlist-right
    .shortlist-right-bottom
    .shortlist-right-content
    .job-list-container
    .shortlist-jobItem {
    width: 95%;
    cursor: pointer;
  }

  .shortlist-container .shortlist-right {
    padding: 2rem 1rem;
  }
}

@media only screen and (max-width: 750px) {
  .shortlist-container
    .shortlist-right
    .shortlist-right-bottom
    .shortlist-header
    .shortlist-header-right {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
  }
}
