.payment-container {
  margin: 1rem 1.2rem;
}

.payment-container h2 {
  color: #245293;
  font-size: 24px;
  font-weight: 700;
  line-height: 38px; /* 38.4px */
  letter-spacing: -1px;
}

.payment-container .payment-content {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.payment-container .payment-content .box-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;
}

.payment-container .payment-content .payment-bottom {
  padding: 1.5rem 1.5rem;
  background-color: #fff;
  border-radius: 12px;
  min-height: 300px;
  box-shadow: 4px 6px 12px 0px rgba(36, 82, 147, 0.04);
  display: flex;
  flex-direction: column;
}

.payment-container .payment-content .payment-bottom h4 {
  font-size: 20px;
}

.payment-container .payment-content .payment-bottom .table-container {
  width: 100%;
  overflow-x: auto;
  margin-top: 1rem;
}

.payment-container .payment-content .payment-bottom .mobile-table-con {
  display: none;
}

.payment-container .payment-content .payment-bottom .table-container .table {
  width: 100%;
  border: 1px solid #ddd;
}

.payment-container
  .payment-content
  .payment-bottom
  .status {
  display: flex;
  align-items: center;
}

.payment-container
  .payment-content
  .payment-bottom
  .pend {
  color: #f5a656;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  border-radius: 24px;
  background: rgba(245, 166, 86, 0.15);
  padding: 5px 9px;
  width: fit-content;
}

.payment-container
  .payment-content
  .payment-bottom
  .succ {
  color: green;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  border-radius: 24px;
  background: rgb(222, 250, 222);
  padding: 5px 9px;
  width: fit-content;
}

.payment-container .payment-content .payment-bottom .table-container .table th,
.table td {
  padding: 10px 10px 10px 16px;
  border: 1px solid transparent;
  text-align: left;
}

.payment-container .payment-content .payment-bottom .table-container .table th {
  background-color: #f2f2f2;
  color: #333;
}

.payment-container .payment-content .payment-bottom .payment-board {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin: 10px 0 0;
}

.payment-container .payment-content .payment-bottom p {
  color: #808080;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: -0.5px;
}

.modal-container-inner {
  padding: 2rem 1.5rem;
}

.modal-container-inner .warning-acc {
  padding: 1rem 1rem;
  background-color: rgb(243, 243, 193);
  border: 1.5px solid rgb(253, 253, 0);
}

.modal-container-inner .warning-acc p {
  color: #000;
  font-size: 15px;
  font-weight: 500;
  
}

.modal-container-inner .cancel-btn-only {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.modal-container-inner .success-withdraw-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.7rem;
  margin-bottom: 1.5rem;
  margin-top: 2.5rem;
}

.modal-container-inner .success-withdraw-section img {
  margin-bottom: 1rem;
}

.modal-container-inner .success-withdraw-section h5 {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -1px;
  text-align: center;
  color: #021424;
}

.modal-container-inner .success-withdraw-section p {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.5px;
  text-align: center;
  color: #808080;
  width: 90%;
}

.modal-container-inner .input-modal-cover {
  margin: 2rem 0 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.modal-container-inner .input-modal-cover .second-input-part-withdraw p {
  color: #245293;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-decoration-line: underline;
  margin-top: 10px;
}

.modal-container-inner .input-modal-cover .input-modal-cover-otp h5 {
  color: #021424;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -1px;
}

.modal-container-inner .input-modal-cover .input-modal-cover-otp p {
  margin-top: 0.5rem;
  color: #808080;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.5px;
}

.modal-container-inner .input-modal-cover .input-modal-cover-inner-bg {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.modal-container-inner .input-modal-cover .input-modal-cover-inner-bg .child {
  width: 50%;
}

.modal-container-inner .input-modal-cover .card-box {
  border-radius: 12px;
  border: 1px solid #808080;
  display: flex;
  padding: 24px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.modal-container-inner .input-modal-cover .card-box .first-section {
  display: flex;
  align-items: center;
  gap: 10px;
}

.modal-container-inner .input-modal-cover .card-box .first-section .card-cover {
  background-image: linear-gradient(to right, #f4fcff, #c8f3ff);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-container-inner
  .input-modal-cover
  .card-box
  .first-section
  .text-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.modal-container-inner
  .input-modal-cover
  .card-box
  .first-section
  .text-section
  h2 {
  color: #021424;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: -1px;
}

.modal-container-inner
  .input-modal-cover
  .card-box
  .first-section
  .text-section
  p {
  color: #808080;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -1px;
}

.modal-container-inner .input-modal-cover .acc-title {
  height: 48px;
  color: #245293;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.5px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 1px solid #b6ebff;
  background: #f4fcff;
}

.modal-container-inner .footer-btns-bg {
  width: "100%";
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.modal-container-inner .footer-btns-bg button:nth-child(2) {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #245293;
  color: #245293;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
}

@media only screen and (max-width: 1000px) {
  .payment-container {
    margin: 6rem 1.2rem 1rem;
  }
}

@media only screen and (max-width: 800px) {
  .payment-container .payment-content .box-container {
    gap: 0.5rem;
  }
}

@media only screen and (max-width: 650px) {
  .payment-container {
    margin: 6rem 0.5rem 1rem;
  }

  .payment-container .payment-content .box-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
  }

  .payment-container .payment-content .payment-bottom {
    padding: 1.5rem 1rem;
  }

  .payment-container .payment-content .payment-bottom .table-container {
    display: none;
  }

  .payment-container .payment-content .payment-bottom .mobile-table-con {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
  }

  .payment-container
    .payment-content
    .payment-bottom
    .mobile-table-con
    .mobile-single-table {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 7px;
    width: 100%;
    margin: 10px 0;
  }

  .payment-container
    .payment-content
    .payment-bottom
    .mobile-table-con
    .mobile-single-table
    .cols-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .payment-container
    .payment-content
    .payment-bottom
    .mobile-table-con
    .mobile-single-table
    .cols-mobile
    h6 {
    font-size: 16px;
    font-weight: 600;
  }
}

@media only screen and (max-width: 475px) {
  .modal-container-inner {
    padding: 1rem 0.5rem;
  }
}
