.profile-edit-date-cv-body {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.profile-edit-date-cv-body .input-edit-container {
  padding-left: 10px;
  border-radius: 100px;
  border: 1px solid #efefef;
  background: #fff;
  width: 50%;
  height: 48px;
  display: flex;
  align-items: center;
}

.profile-edit-date-cv-body .input-edit-container input {
  border: none;
  width: 100%;
  padding-right: 10px;
  height: 100%;
  outline: none;
  background-color: transparent;
}

@media only screen and (max-width: 600px) {
  .profile-edit-date-cv-body .input-edit-container {
    border-radius: 50px;
    width: 100%;
    height: 50px;
  }
}
