.explore-employees-container {
    width: 85%;
    margin: 0 auto;
    padding: 2rem 2.5rem;
    background-color: #fff;
    margin-bottom: 35px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
}

.explore-employees-container .explore-employee-image {
    width: 45%;
    object-fit: cover;
}

.explore-employees-container .explore-employee-image img {
    width: 100%;
}

.explore-employees-container .explore-employee-content {
    flex-basis: 45%;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.explore-employees-container .explore-employee-content h2 {
    font-size: 43px;
    font-weight: 800;
    width: 85%;
    color: #245293;
    line-height: 1.1;
}

.explore-employees-container .explore-employee-content .explore-text {
    text-align: left;
    color: #245293;
    line-height: 1.6;
}

.explore-employees-container .explore-employee-search-box {
    /* border: 1px solid red; */
    width: 90%;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #C8F3FF;
    border-radius: 50px;
}

.explore-employees-container .explore-employee-search-box input {
    border-radius: 10px;
    padding: 6px 0 6px 20px;
    border-left: 1px solid wheat;
    border-radius: 5px;
    border: none;
    outline: none;
    background-color: transparent;
}

.explore-employees-container .home-box-one-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    margin-right: 20px;
}

.explore-employees-container .home-box-one-button button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    background-color: #245293;
    color: #fff;
    font-size: 15px;
    padding: 8px 13px;
    cursor: pointer;
    border-radius: 20px;
    width: 100%;
    outline: none;
    border: none;
}

.explore-employees-container .employee-community {
    display: flex;
    align-items: center;
    gap: 15px;
}

.explore-employees-container .employee-community .employee-community-img {
    width: 140px;
    height: 50px;
}

.explore-employees-container .employee-community .employee-community-img img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.explore-employees-container .employee-community span {
    font-size: 16px;
    font-weight: 400;
    color: #245293;
}

@media only screen and (max-width: 1250px) {
    .explore-employees-container .explore-employee-image {
        width: 40%;
        object-fit: cover;
    }

    .explore-employees-container {
        width: 90%;
        gap: 20;
    }

    .explore-employees-container .explore-employee-content h2 {
        font-size: 35px;
        font-weight: 700;
    }
}

@media only screen and (max-width: 900px) {
    .explore-employees-container {
        width: 95%;
        padding: 2rem 0.5rem;
        flex-direction: column;
    }

    .explore-employees-container .explore-employee-image {
        width: 95%;
        margin: 0 auto;
    }

    .explore-employees-container .explore-employee-content h2 {
        font-size: 25px;
        font-weight: 800;
        width: 95%;
    }

    .explore-employees-container .explore-employee-content {
        margin: 1.5rem 1rem;
    }

    .explore-employees-container .explore-employee-search-box {
        width: 100%;
        justify-content: center;
        flex-direction: column;
        background-color: transparent;
    }

    .explore-employees-container .explore-employee-search-box input {
        padding: 1rem 0.8rem;
        width: 100%;
        margin-bottom: 1rem;
        background-color: #C8F3FF;
        border-radius: 17px;
    }

    .explore-employees-container .home-box-one-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-right: 0;
    }

    .explore-employees-container .home-box-one-button button {
        gap: 6px;
        font-size: 19px;
        padding: 6px 8px;
        padding: 0.7rem 0.5rem;
        border-radius: 17px;
    }
}