.job-activity-container .job-activity-right {
  width: 100%;
  padding: 0 1rem 4rem;
}

.job-activity-container
  .job-activity-right
  .job-activity-right-top
  .activity-box-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 1rem auto;
  padding: 0.5rem 0.2rem;
}

.job-activity-container
  .job-activity-right
  .job-activity-right-top
  .activity-box-head
  h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 56px; /* 56.96px */
  color: #465174;
}

.job-activity-container
  .job-activity-right
  .job-activity-right-top
  .activity-box-head
  .link {
  padding: 0.5rem 1.2rem;
  border-radius: 20px;
  outline: none;
  border: none;
  background-color: #3a649e;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  cursor: pointer;
}

.job-activity-container .job-activity-right-bottom {
  width: 100%;
  margin: 0 auto;
  border-radius: 20px;
  background-color: #fff;
  padding: 1rem 0.5rem 3rem;
  min-height: 600px;
}

.job-activity-container .job-activity-right-bottom .job-activity-box {
  padding: 0 0.4rem;
}

.job-activity-container .job-activity-right-bottom .job-activity-tabs {
  padding: 2rem 0 4rem 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.job-activity-container
  .job-activity-right-bottom
  .job-activity-box
  .job-activity-tabs
  span {
  margin-right: 15px;
  background-color: #f1f1f1;
  color: #606060;
  font-size: 14px;
  font-weight: 600;
  line-height: 125%;
  letter-spacing: -0.16px;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
}

.job-activity-container
  .job-activity-right-bottom
  .job-activity-box
  .job-activity-tabs
  span.active {
  background-color: #18a0fb !important;
  color: #fff;
}

.job-activity-container .job-activity-right-bottom .job-activity-content {
  /* border: 1px solid red; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.job-activity-container
  .job-activity-right-bottom
  .job-activity-content
  .job-activity-jobItem {
  width: 31.5%;
  cursor: pointer;
}


@media only screen and (max-width: 1250px) {
  .job-activity-container
    .job-activity-right-bottom
    .job-activity-content
    .job-activity-jobItem {
    width: 40%;
    cursor: pointer;
  }
}

@media only screen and (max-width: 1000px) {
  .job-activity-container .job-activity-right {
    padding: 4.5rem 1rem 4rem;
  }
}

@media only screen and (max-width: 750px) {
  .job-activity-container .job-activity-right-bottom .job-activity-tabs {
    gap: 15px;
  }

  .job-activity-container
    .job-activity-right-bottom
    .job-activity-box
    .job-activity-tabs
    span {
    margin-right: 0;
  }

  .job-activity-container .job-activity-right {
    padding: 3rem 1rem;
  }

  .job-activity-container
    .job-activity-right-bottom
    .job-activity-content
    .job-activity-jobItem {
    width: 100%;
    cursor: pointer;
  }

  .job-activity-container
    .job-activity-right
    .job-activity-right-top
    .activity-box-head {
    margin-top: 3rem;
  }
}

@media only screen and (max-width: 600px) {
  .job-activity-container .job-activity-right-bottom .job-activity-tabs {
    justify-content: center;
  }
}
