.dashboard-modal h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #245293;
}

.dashboard-modal .box-timesheet {
  padding: 1rem;
}

.dashboard-modal .box-timesheet .loading-timesheet {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.dashboard-modal .box-timesheet .overscroll-box-timesheet {
  height: 200px;
  overflow: scroll;
  overflow-x: hidden;
  margin-right: -17px;
  padding-right: 17px;
}

.spacing-top {
  margin-top: 2rem;
}

.dashboard-modal .box-timesheet {
  margin-top: 1rem;
}

.dashboard-modal .box-timesheet .timerange {
  margin-top: 1.2rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  color: #808080;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.5px;
}

.dashboard-modal .box-timesheet .timerange .each-timerange {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.dashboard-modal .box-timesheet .timerange .middle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-modal .box-timesheet .box-timesheet-input-cover {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 1.5rem;
  width: 100%;
  padding-bottom: 1rem;
  margin-bottom: 0.5rem;
}

.dashboard-modal .box-timesheet .box-timesheet-input-cover .fill-bg {
  flex: 1;
}

.dashboard-modal .box-timesheet .box-timesheet-input-cover .hours-bg label {
  color: #021424;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: -0.5px;
}

.dashboard-modal .box-timesheet .box-timesheet-input-cover .to {
  color: #808080;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: -0.5px;
  margin-top: 2rem;
}

.dashboard-modal .box-timesheet .candidates-result .candidates-result-headings {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f7fa;
  padding: 1rem 0 1rem 1.5rem;
}

.dashboard-modal
  .box-timesheet
  .candidates-result
  .candidates-result-headings
  span {
  width: 15%;
  color: #1c2533;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
}

.dashboard-modal
  .box-timesheet
  .candidates-result
  .candidates-result-box
  {
    min-height: 100px;
    max-height: 300px;
    overflow-y: scroll;
  }

.dashboard-modal
  .box-timesheet
  .candidates-result
  .candidates-result-box
  .single-candidate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0 1rem 1.5rem;
  border-bottom: 1px solid #d8dde5;
}

.dashboard-modal
  .box-timesheet
  .candidates-result
  .candidates-result-box
  .single-candidate
  .single {
  width: 15%;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}

.dashboard-modal
  .box-timesheet
  .candidates-result
  .candidates-result-box
  .single-candidate
  .single
  .icons-btn {
  flex: 1;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.dashboard-modal
  .box-timesheet
  .candidates-result
  .candidates-result-box
  .single-candidate
  .single
  .mobile-time {
  display: none;
}

.dashboard-modal .box-timesheet .add-rows-btn {
  width: 70px;
}

.dashboard-modal .dashboard-modal-btn {
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-modal .dashboard-modal-btn button {
  padding: 0.35rem 0;
  width: 175px;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 100px;
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  cursor: pointer;
}

.dashboard-modal .btn-bg {
  padding: 0rem 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.dashboard-modal .btn-bg .btn {
  width: 20%;
}

.dashboard-modal hr {
  border-top: 1px solid #a9dbee;
  margin: 1rem 0 0 0.4rem;
}

.dashboard-modal .review-logo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dashboard-modal .review-logo .review-logo-img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid grey;
  padding: 5px;
  margin: 0.8rem 0;
}

@media only screen and (max-width: 1100px) {
  .dashboard-modal .box-timesheet .box-timesheet-input-cover {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
  }

  .dashboard-modal .box-timesheet .box-timesheet-input-cover .fill-bg {
    width: 100%;
  }

  .dashboard-modal .box-timesheet .box-timesheet-input-cover .to {
    margin-top: 0;
    align-items: center;
    align-self: center;
  }

  .dashboard-modal .box-timesheet .box-timesheet-input-cover .hours-bg {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 30%;
  }
}

@media only screen and (max-width: 1000px) {
  .dashboard-modal .box-timesheet {
    padding: 0rem;
  }

  .dashboard-modal .box-timesheet .candidates-result {
    border: none;
    height: 200px;
    overflow-y: scroll;
  }

  .dashboard-modal
    .box-timesheet
    .candidates-result
    .candidates-result-headings {
    display: none;
  }

  .dashboard-modal
    .box-timesheet
    .candidates-result
    .candidates-result-box
    .single-candidate {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1rem 1rem 1rem 0;
    border-bottom: 1px solid #d8dde5;
    gap: 1rem;
  }

  .dashboard-modal
    .box-timesheet
    .candidates-result
    .candidates-result-box
    .single-candidate
    .single {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
  }

  .dashboard-modal
    .box-timesheet
    .candidates-result
    .candidates-result-box
    .single-candidate
    .single
    .mobile-time {
    display: block;
    font-weight: 700;
  }
}

@media only screen and (max-width: 800px) {
  .dashboard-modal .experience-review-box {
    padding: 0.5rem 0;
  }

  .dashboard-modal .experience-review-box textarea {
    height: 150px;
    border-radius: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .dashboard-modal .box-timesheet {
    border-radius: 8px;
  }

  .dashboard-modal .box-timesheet .timerange {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
    color: #808080;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.5px;
  }

  .dashboard-modal .box-timesheet .timerange .each-timerange {
    display: flex;
    align-items: center;
    gap: 0.2rem;
  }

  .dashboard-modal .box-timesheet .timerange .middle {
    display: none;
  }

  .dashboard-modal-box .dashboard-modal .btn-bg .btn {
    width: 40%;
  }
}

@media only screen and (max-width: 500px) {
  .dashboard-modal .dashboard-modal-btn button {
    width: 150px;
  }
}
