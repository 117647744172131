.jobListings-searchbox {
  padding-top: 120px;
  width: 85%;
  margin: 0 auto;
  display: flex;
  gap: 30px;
  justify-content: space-between;
  padding-bottom: 2rem;
  min-height: 100vh;
}

.jobListings-searchbox .filter-box-big-screen {
  width: 30%;
}

.jobListings-searchbox .jobListings-search-filter-results {
  width: 70%;
}

.jobListings-searchbox .jobListings-search-filter-results .inner {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.jobListings-searchbox .jobListings-search-filter-results .loading-screen-jobs {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.jobListings-searchbox .jobListings-search-filter-results .joblistings-jobitem {
  background-color: #fff;
  cursor: pointer;
  border-radius: 10px;
}

.filter-small-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@media only screen and (max-width: 900px) {
  .jobListings-searchbox {
    padding-top: 10rem;
    width: 95%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    padding-bottom: 2rem;
  }

  .jobListings-searchbox .filter-box-big-screen {
    width: 100%;
    height: auto;
  }

  .jobListings-searchbox .jobListings-search-filter-results {
    width: 100%;
  }
}
