.regular-dashboard {
  width: 80%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-image: linear-gradient(to right, #f4fcff, #c8f3ff);
  height: fit-content;
}

.regular-dashboard .inner {
  max-width: 1100px;
  height: fit-content;
  padding-top: 1rem;
  width: 100%;
  min-height: 100vh;
}

.dash-mobile {
  display: none;
}

@media only screen and (max-width: 1000px) {
  .dash-desktop {
    display: none;
  }

  .dash-mobile {
    display: block;
  }

  .regular-dashboard {
    width: 100%;
    position: static;
    display: flex;
    justify-content: center;
    background-image: linear-gradient(to right, #f4fcff, #c8f3ff);
    height: fit-content;
  }

  .regular-dashboard .inner .right-icons {
    display: none;
  }
}
