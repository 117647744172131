.footer-container {
  width: 85%;
  margin: 0 auto;
  padding: 2rem 1rem;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.footer-container .footer-box-left {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.footer-container .footer-box-left .footer-logo-header {
  display: flex;
  align-items: center;
  gap: 20px;
}

.footer-container .footer-box-left .footer-logo-header .footer-sec {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.footer-container .footer-box-left span {
  font-size: 16px;
  color: #245293;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3ms ease;
}

.footer-container .footer-box-left .space {
  display: flex;
  align-items: center;
  gap: 15px;
}

.footer-container .footer-box-left span:hover {
  color: grey;
}

.footer-container .footer-box-left .footer-rights {
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
}

.footer-container .footer-box-right {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.footer-container .footer-box-right span {
  font-size: 20px;
  color: #245293;
  font-weight: 600;
}

.footer-container .footer-box-right .flex-contact {
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 60px;
}

.footer-container .footer-box-right .flex-contact .foot-logo-btn {
  cursor: pointer;
}

.footer-container .footer-box-right .flex-contact .foot-logo-btn img {
  width: 150px;
}

.footer-container .signup-modal h4 {
    font-size: 28px;
    color: #245293;
    font-weight: 600;
    line-height: 40px;
    text-align: center;
    text-decoration: underline;
    z-index: 50;
}

.footer-container .signup-modal p {
    font-size: 16px;
    color: #000;
    font-weight: 400;
    margin: 5px 0;
}

.footer-container .signup-modal .head {
    font-size: 18px;
    color: #245293;
    font-weight: 600;
}


@media only screen and (max-width: 1250px) {
  .footer-container {
    width: 90%;
  }
}

@media only screen and (max-width: 900px) {
  .footer-container {
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .footer-container .footer-box-left span {
    cursor: pointer;
    transition: all 0.3ms ease;
  }

  .footer-container .footer-box-right {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 1.5rem;
  }

  .footer-container .content-2 {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 1rem 0;
  }
}

@media only screen and (max-width: 700px) {
  .footer-container .footer-box-left .footer-logo-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-container .footer-box-left .footer-logo-header .footer-sec {
   flex-direction: column;
   align-items: flex-start;
  }
}
