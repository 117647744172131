.customautocomplete {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.options-body {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 20px;
}

.options-body .startAdornment {
  display: flex;
  align-items: center;
  justify-self: center;
  justify-content: center;
}

.options-body span {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}