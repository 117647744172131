.signUp-container {
  display: flex;
  flex-direction: row;
}

/* .signUp-container .signup-modal h4 {
  font-size: 28px;
  color: #245293;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
  text-decoration: underline;
}

.signUp-container .signup-modal p {
  font-size: 16px;
  color: #000;
  font-weight: 400;
  margin: 5px 0;
}

.signUp-container .signup-modal .head {
  font-size: 18px;
  color: #245293;
  font-weight: 600;
} */

/* left side */
.signUp-container .signUp-left {
  position: fixed;
  width: 100%;
  height: 100%;
  width: 40%;
  max-height: 100vh;
  overflow-y: hidden;
}

.signUp-container .signUp-left .signUp-left-content {
  background: url(../../../assets/bg-g.png);
  padding: 0.4rem 1.5rem 2rem 5rem;
  height: 100vh;
}

.signUp-container .signUp-left .logo {
  height: 60px;
  width: 60px;
  margin: 0.3rem 0 2rem;
  cursor: pointer;
}

.signUp-container .signUp-left .logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.signUp-container .signUp-left .signUp-left-text h3 {
  font-size: 35px;
  color: #fff;
  width: 95%;
  margin: 0 auto;
  margin: 1rem 0;
  font-weight: 800;
  line-height: 40px;
}

.signUp-container .signUp-left .signUp-left-text p {
  font-size: 16px;
  color: #fff;
  width: 70%;
  margin: 0 auto;
  margin: 0.4rem 0;
  margin-bottom: 0rem;
}

.signUp-container .signUp-left .signUp-left-image {
  margin: 0 auto;
  position: relative;
}

.signUp-container .signUp-left .signUp-left-image .doc {
  width: fit;
  height: fit;
  object-fit: cover;
}

.signUp-container .signUp-left .signUp-left-image .white-box {
  position: absolute;
  right: 30px;
  top: 6rem;
}

/* left side */

/* right side */

.signUp-container .signUp-right {
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 60%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 2rem 0 5rem;
  height: fit-content;
  min-height: 100vh;
}

.signUp-container .signUp-right .signup-right-body {
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.signUp-container .signUp-right .login-button-box {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-left: auto;
}

.signUp-container .signUp-right .login-button-box img {
  display: none;
}

.signUp-container .signUp-right .login-button-box .login-btn-right-btn {
  width: 100px;
  padding: 0.2rem;
  border-radius: 20px;
  border: none;
  outline: none;
  background-color: #245293;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  line-height: 30px;
  font-family: 600;
}

.signUp-container .signUp-right .signUp-right-content {
  width: 80%;
  padding: 2rem 0 2rem;
}

.signUp-container .signUp-right .signUp-right-content form .orbg {
  margin: 2rem 0;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.signUp-container .signUp-right .signUp-right-content form .orbg .border {
  height: 1px;
  background-color: #828282;
  width: 50%;
}

.signUp-container .signUp-right .signUp-right-content form .orbg h5 {
  font-size: 16px;
  color: #828282;
  line-height: 24px;
  font-weight: 400;
}

/* forms and label */
.signUp-container .signUp-right .signUp-right-content .form-label {
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* forms and label */

/* checkbox/term */
.signUp-container .signUp-right .signUp-right-content form .terms {
  padding: 1.2rem 0 0.3rem;
  display: flex;
  gap: 10px;
}

.signUp-container .signUp-right .signUp-right-content form .terms input {
  height: 24px;
  width: 24px;
}

.signUp-container .signUp-right .signUp-right-content form .terms span {
  color: #828282;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
}

.signUp-container
  .signUp-right
  .signUp-right-content
  form
  .terms
  span
  .terms-modal {
  color: #245293;
  font-weight: 600;
  cursor: pointer;
}
/* checkbox/term */

/*sign up button */
.signUp-container .signUp-right .signUp-right-content form .signUp-btn {
  width: 100%;
  border-radius: 30px;
  border: none;
  outline: none;
  background-color: #245293;
  color: #fff;
  cursor: pointer;
  margin-top: 1rem;
  height: 45px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.otp-bg {
  height: 250px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 3rem 2rem;
}

.otp-bg h6 {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.otp-bg .otp-input-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}

.otp-bg .otp-input-bg input {
  width: 50px;
  padding: 10px;
  outline: none;
  text-align: center;

  &:focus {
    border: 2px solid green;
  }
}

.otp-bg .otp-btn-bg {
    margin-top: 2rem;
}

@media only screen and (max-width: 1500px) {
  .signUp-container .signUp-left .signUp-left-content {
    padding: 0.4rem 1rem 2rem 2.5rem;
  }

  .signUp-container .signUp-left .signUp-left-text h3 {
    font-size: 30px;
    color: #fff;
    width: 80%;
    margin: 0 auto;
    margin: 0.5rem 0;
  }

  .signUp-container .signUp-left .signUp-left-image .doc {
    height: 450px;
    object-fit: contain;
  }

  /* .signUp-container .signUp-left .signUp-left-image .doc {
        height: 400px;
        
    } */
}

@media only screen and (max-width: 1200px) {
  .signUp-container .signUp-right {
    position: static;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem 0 5rem;
    background-color: #fff;
  }

  .signUp-container .signUp-right .signup-right-body {
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .signUp-container {
    flex-direction: column;
  }

  .signUp-container .signUp-left {
    display: none;
  }

  .signUp-container .signUp-right .signUp-right-content {
    width: 100%;
    padding: 1.5rem 0.5rem;
    margin-top: 3rem;
  }

  .signUp-container .signUp-right .login-button-box {
    width: 100%;
    padding: 0;
    width: 98%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.2rem;
  }

  .signUp-container .signUp-right .login-button-box img {
    display: block;
  }
}

@media only screen and (max-width: 800px) {
  .signUp-container .signUp-right .signup-right-body {
    width: 95%;
  }
}
